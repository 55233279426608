// function setCookie(name, value, days) {
//   var expires = "";
//   if (days) {
//     var date = new Date();
//     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//     expires = "; expires=" + date.toUTCString();
//   }
//   document.cookie = name + "=" + (value || "") + expires + "; path=/";
// }
//
// function getCookie(name) {
//   var nameEQ = name + "=";
//   var ca = document.cookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) == ' ') c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// }

document.addEventListener('lazybeforeunveil', function(e){
  var bg = e.target.getAttribute('data-bg');
  if(bg){
    e.target.style.backgroundImage = 'url(' + bg + ')';
  }
});

var cookieConcent = getCookie('cookieConcent');

function deleteCookies() {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
      var p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      ;
      d.shift();
    }
  }
}


$(document).ready(function () {
  if (!cookieConcent) {
    $('.cookies-header').show();
  }

  $('.settings-button').on('click', function (e) {
    $('#cookie-concent').modal('show');
  });
  $('#i-want-cookies').on('click', function (e) {
    $('#cookie-concent').modal('hide');
    $('.cookies-header').hide();
    setCookie('cookieConcent', true, 365);
  });

  $('#save-cookie-concent').on('click', function (e) {
    $('#cookie-concent').modal('hide');
    var promoCookie = $('#promo-cookies').is(":checked");
    $('.cookies-header').hide();

    if (!promoCookie) {
      deleteCookies();
    }
    setCookie('cookieConcent', promoCookie, 365);
    window.location.reload();
  });

});


Date.isLeapYear = function (year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};

function days_of_a_year(year) {

  return isLeapYear(year) ? 366 : 365;
}

function isLeapYear(year) {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

var currencyWithSpace;
var currencyWithNoSpace;
var calculatorCurrency;

function getCurrencySymbol(currencyValue) {
  if (currencyValue === 'USD' || currencyValue === '$') {
    currencyValue = '$';
  } else if (currencyValue === 'EUR' || currencyValue === '€') {
    currencyValue = '€';
  } else {
    currencyValue = 'MDL';
  }
  currencyWithSpace = ' ' + currencyValue;
  currencyWithNoSpace = currencyValue;
}

function getCurrencyLetterValue() {
  if (currencyWithNoSpace === "$") {
    return "USD";
  } else if (currencyWithNoSpace === "€") {
    return "EUR";
  } else {
    return "MDL";
  }
}

var language = 'ro';
var Slider = (function () {
  var Public = {
    animations: [],
    animationQuantity: 0,

    Init: function (type) {
      if (type === "main") {
        Public.InitSlickMainSlider();
      } else if (type === "loan") {
        Public.InitObtainLoanSlider();
      } else if (type === "footer") {
        Public.InitFooterSlider();
      } else if (type === "about-us-page") {
        Public.InitAboutUsPagePopUpSlider();
      }
    },

    getUrlParameter: function (sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split('&');
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
          return sParameterName[1];
        }
      }
    },

    InitSlickMainSlider: function () {
      var $slickMainBanner = $(".slider-wrapper .slider");

      // $.fn.randomize = function (selector) {
      //   var $elems = selector ? $(this).find(selector) : $(this).children(),
      //     $parents = $elems.parent();
      //   $parents.each(function () {
      //     $(this).children(selector).sort(function () {
      //       return Math.round(Math.random()) - 0.5;
      //     }).detach().appendTo(this);
      //   });
      //
      //   return this;
      // };
      //
      // $slickMainBanner.randomize('.slide-item');

      $slickMainBanner
        .slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 6000,
          dots: true,
          infinite: true,
          adaptiveHeight: true,
          arrows: false
        })
        .on("afterChange", function (event, slick, currentSlide) {
          var currentSlideContainer = $(slick.$slider)
            .find(".slide-item.slick-active")
            .data("slider-type");
          d = new Date();

          if (currentSlideContainer === "video") {
            stopVideo();
            playVideoJS();
          } else if (currentSlideContainer === "pulse-video") {
            $(
              '#osciloscop-slider .slide[data-slide-number="0"]'
            ).css(
              "background-image",
              "url(" +
              "/video/main-slider/" +
              0 +
              ".gif?" +
              d.getTime() +
              ")"
            );
          }
        });

      function stopVideo() {
        var vid = document.getElementById("carVideo");
        vid.currentTime = 0;
        vid.pause();
      }

      function playVideoJS() {
        var vid = document.getElementById("carVideo");
        vid.currentTime = 0;
        vid.play();
      }

      $slickMainBanner.on("init", function (slick) {
        stopVideo();
        playVideoJS();
        $('#osciloscop-slider .slide[data-slide-number="0"]').css(
          "background-image",
          "url(" + "video/main-slider/0.gif" + ")"
        );
      });

      //--------------------------------JS for Oscilloscope-------------

      function randomUrlGifs(counter) {
        d = new Date();
        $(
          '#osciloscop-slider .slide[data-slide-number="' +
          counter +
          '"]'
        ).css(
          "background-image",
          "url(" +
          "/video/main-slider/" +
          counter +
          ".gif?" +
          d.getTime() +
          ")"
        );
      }

      (function () {
        var $osciloscopBtn = $("#osciloscop-button");
        var $slide = $("#osciloscop-slider > .slide-viewer > .slide");
        var counter = 0;

        $osciloscopBtn.on("click", function () {
          counter++;
          $(
            '#osciloscop-slider .slide[data-slide-number="' +
            counter +
            '"]'
          ).addClass("active");

          randomUrlGifs(counter);

          if (counter === 1) {
            setTimeout(function () {
              $osciloscopBtn.css(
                "background-image",
                "url('/images/osciloscope-active-btn.png')"
              );
            }, 500);

            setTimeout(function () {
              $osciloscopBtn.css({
                "background-image":
                  "url('/images/osciloscope-next-btn.png')"
              });
              $osciloscopBtn.removeClass("activate-animation");
            }, 2900);
          } else if (counter > 5) {
            setTimeout(function () {
              $osciloscopBtn.css(
                "background-image",
                "url('/images/osciloscope-inactive-btn.png')"
              );
              $osciloscopBtn.addClass("activate-animation");
            }, 1800);

            counter = 0;

            for (i = 0; i < $slide.length; i++) {
              $(
                '#osciloscop-slider .slide[data-slide-number="' +
                i +
                '"]'
              ).removeClass("active");
            }

            $(
              '#osciloscop-slider .slide[data-slide-number="' +
              counter +
              '"]'
            ).css(
              "background-image",
              "url(" +
              "/video/main-slider/" +
              counter +
              ".gif?" +
              d.getTime() +
              ")"
            );
          }
        });
      })();

      // VIDEO RESCALE

      function vidRescale() {
        var w = $(".banners-block").outerWidth(),
          h = $(".banners-block").outerHeight();
        if (h > (w * 680) / 1920) {
          $(".banner-video video").css({height: h});
          $(".banner-video").css({
            left: -($("body").outerWidth() - w) / 2
          });
        } else {
          $(".banner-video video").css({width: "100%"});
          $(".banner-video video").css({height: "auto"});
          if (w > 480) {
            $(".banner-video video").css({left: 0});
          }
        }
      }

      $(window).on("load resize", function () {
        vidRescale();
      });
    },

    InitObtainLoanSlider: function () {

      var $legalBtnToggle = $('.obtain-loan-slider .main-full-slider-area .info-slider .legal-entity-btn > a');
      var $hidenLegalData = $('.obtain-loan-slider .main-full-slider-area .info-slider .hiden-legal-entity-data');

      $legalBtnToggle.on('click', function () {
        $hidenLegalData.toggleClass('active');
      })


      var $elemWidth = $(
        ".obtain-loan-slider .car-slider .content .step"
      )[0].clientWidth;
      var $hiddenMobileSlider = $(
        ".obtain-loan-slider .hidden-car-slider-on-mobile .hidden-mobile-slider"
      );
      var $fullSliderArea = $(
        ".obtain-loan-slider .main-full-slider-area"
      );

      animations = Public.animations = Calculator.MakeCalculus.animateMovingCar(
        Public.animationQuantity,
        $elemWidth
      );

      $hiddenMobileSlider.slick({
        dots: true,
        arrows: true,
        infinite: false,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: $fullSliderArea,
        prevArrow:
          "<img class='slick-prev' src='/images/slick-left-arrow-mob.png'>",
        nextArrow:
          "<img class='slick-next' src='/images/slick-right-arrow-mob.png'>"
      });

      $fullSliderArea.slick({
        dots: true,
        arrows: true,
        infinite: false,
        adaptiveHeight: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: $hiddenMobileSlider,
        prevArrow:
          "<img class='slick-prev' style='pointer-events:none;' src='/images/slick-left-arrow-mob.png'>",
        nextArrow:
          "<img class='slick-next' src='/images/slick-right-arrow-mob.png'>",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

      if ($fullSliderArea.find("ul.slick-dots")) {
        $fullSliderArea.find("ul.slick-dots").remove();
      }

      $(".obtain-loan-slider .car-slider .animated-car .car-with-no_wheel ").on("click", event => {
        $fullSliderArea.slick("slickNext");
      });

      $(".obtain-loan-slider .car-slider .step").on("click", event => {
        var slideGoTo = $(event.target).data('slide');
        var currentSlide = $fullSliderArea.slick("slickCurrentSlide");
        if (slideGoTo > currentSlide) {
          $fullSliderArea.slick("slickNext");
        } else if (slideGoTo < currentSlide) {
          $fullSliderArea.slick("slickPrev");
        }
      });

      $(".bullet-blue-circles .step").on("click", function (event) {
        var slideNumber = $(event.target).attr("data-page");
        if (!slideNumber) {
          slideNumber = $(event.target)
            .parent()
            .attr("data-page");
        }
        $fullSliderArea.slick("slickGoTo", slideNumber);
      });

      $fullSliderArea.on("beforeChange", function (
        event,
        slick,
        currentSlide,
        nextSlide
      ) {
        var steps = $(".car-slider .content .step");
        var previous_element = steps.get(currentSlide);
        var next_element = steps.get(nextSlide);

        $(previous_element).css(
          "background-image",
          'url("' + $(previous_element).data("bg-active") + '")'
        );

        $(".animated-car").addClass("driving");

        if (nextSlide > currentSlide && nextSlide == steps.length - 1) {
          $(".slick-next").css("pointer-events", "none");
        } else {
          $(".slick-next").css("pointer-events", "auto");
        }

        if (nextSlide < currentSlide && nextSlide === 0) {
          $(".slick-prev").css("pointer-events", "none");
        } else {
          $(".slick-prev").css("pointer-events", "auto");
        }
        if (currentSlide < nextSlide) {
          if (animations[nextSlide - 1].reversed)
            animations[nextSlide - 1].reverse();
          animations[nextSlide - 1].play();
        } else if (currentSlide > nextSlide) {
          if (!animations[nextSlide].reversed)
            animations[nextSlide].reverse();
          animations[nextSlide].play();
        }

        $(previous_element).css(
          "background-image",
          'url("' + $(previous_element).data("bg-inactive") + '")'
        );
        $(next_element).css(
          "background-image",
          'url("' + $(next_element).data("bg-inactive") + '")'
        );

        if ($(previous_element).data("car-backward")) {
          $(previous_element)
            .find(".to-front")
            .css("z-index", 1);
        }
      });

      $fullSliderArea.on("afterChange", function (
        event,
        slick,
        currentSlide
      ) {
        var currentIndex = $(".slick-current").attr("data-slick-index");
        currentIndex = parseInt(currentIndex);

        var steps = $(".car-slider .content .step");
        var current_element = steps.get(currentSlide);
        $(".animated-car").removeClass("driving");

        $(steps)
          .find(".titleForStep")
          .css("font-weight", "400");

        current_element.firstChild.style.fontWeight = 600;

        if ($(current_element).data("car-backward")) {
          $(current_element)
            .find(".to-front")
            .css("z-index", 2);
        }
        $(current_element).css(
          "background-image",
          'url("' + $(current_element).data("bg-active") + '")'
        );
      });

      var goToSlideParam = Public.getUrlParameter('et');
      if (goToSlideParam !== '') {
        $fullSliderArea.slick("slickGoTo", parseInt(goToSlideParam) - 1);
      }

      $(".video-slider").click(function () {
        var video =
          '</iframe><iframe class="embed-responsive-item" width="560" height="315"\n' +
          'src="' +
          $(this).attr("data-video") +
          '" frameborder="0"\n' +
          'allow="autoplay; encrypted-media" allowfullscreen></iframe>';
        $(this).replaceWith(video);
      });

      $(".ytp-large-play-button").click(function () {
        var placeholder = $(this).parent(".video-slider");
        var video =
          '</iframe><iframe class="embed-responsive-item" width="560" height="315"\n' +
          'src="' +
          placeholder.attr("data-video") +
          '" frameborder="0"\n' +
          'allow="autoplay; encrypted-media" allowfullscreen></iframe>';
        placeholder.replaceWith(video);
      });
    },

    InitFooterSlider: function () {
      $(".footer-slide").on("click", function (event) {
        $(".footer-slide").removeClass("selected");
        $(event.target)
          .closest(".footer-slide")
          .addClass("selected");
      });
    },

    InitAboutUsPagePopUpSlider: function () {
      $(".album-section .album .js-open-modal").on("click", function () {
        var modalNumber = $(this).data("active-modal");
        var $myModal = $("#album-modal-" + modalNumber);

        var $mainSLider = $(
          " #album-modal-" +
          modalNumber +
          "  .slick-sliders-section .main-image-slider .slide-content"
        );
        var $smallSlider = $(
          " #album-modal-" +
          modalNumber +
          " .slick-sliders-section .secondary-image-slider .small-slide-content"
        );

        $myModal.modal("show");

        $myModal.on("shown.bs.modal", function () {
          $mainSLider.resize();
          $smallSlider.resize();

          $mainSLider.not(".slick-initialized").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: $smallSlider,
            infinite: true,
            prevArrow:
              "<img style='height: 35px;' class='slick-prev ' src='/images/left-arrow-mob.png'  >",
            nextArrow:
              "<img style='height: 35px;' class='slick-next ' src='/images/right-arrow-mob.png' >"
          });

          $smallSlider.not(".slick-initialized").slick({
            slidesToShow: 7,
            slidesToScroll: 1,
            asNavFor: $mainSLider,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            infinite: true,
            arrows: true,
            prevArrow:
              "<img style='height: 35px;' class='slick-prev ' src='/images/left-arrow-mob.png' >",
            nextArrow:
              "<img style='height: 35px;' class='slick-next ' src='/images/right-arrow-mob.png' >",
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              }
            ]
          });
        });

        $myModal.on("hidden.bs.modal", function () {
          $mainSLider.slick("unslick");
          $smallSlider.slick("unslick");
        });

        $myModal.find(".close").on("click", function () {
          // $mainSLider.slick('unslick');
          // $smallSlider.slick('unslick');
        });
      });
    }
  };
  return Public;
})();

var Navbar = (function () {
  var Public = {
    Init: function () {
      var windowWidth = window.innerWidth;
      var user = null;
      if (localStorage.getItem('jhi-sesam')) {
        user = JSON.parse(b64d(JSON.parse(localStorage.getItem('jhi-sesam'))));
        if (user && user.username) {
          $('nav .user-name').text(user.username);
        }
      }
      $signInInput = $(
        ".header-section .navbar .signin-box .app-signin input"
      );

      $signInForm = $(
        ".header-section .navbar .signin-box .app-signin"
      );

      $signInInput.on("input", function (e) {
        var $signInInput = $(e.target.parentElement.parentNode);
        var $inputs = $signInInput.find("input");

        if ($inputs[0].value != "" && $inputs[1].value != "") {
          $signInInput.find("button").removeAttr("disabled");

          $signInInput.find("button").css({
            "background-color": "#0297ff"
          });
        } else {
          $signInInput.find("button").attr("disabled");

          $signInInput.find("button").css({
            "background-color": "#cccccc"
          });
        }
      });

      $signInForm.on('submit', function (e) {
        e.preventDefault();
        $('.error-message').hide();
        var userData = getFormData($(e.target));
        var expression = /\S+@\S+/
        if (expression.test(String(userData.username).toLowerCase())) {
          $.ajax({
            type: "POST",
            url: '/api/authenticate',
            data: JSON.stringify(userData),
            contentType: "application/json",
            success: function (data, textStatus, request) {
              if (data === 'ok') {
                var authHeader = request.getResponseHeader('Authorization');
                var token = authHeader.substring(7, authHeader.length);
                userData.type = 'user';
                sessionStorage.setItem('jhi-authenticationtoken', '"' + token + '"');
                window.location.replace('/app');
              } else {
                $('.error-message').show();
              }
            },
            error: function (error) {
              if (error.status = 401) {
                $('.error-message').show();
              }
            }
          });
        } else {
          $.ajax({
            type: "POST",
            url: '/api/contracte-list/client',
            data: JSON.stringify(userData),
            contentType: "application/json",
            success: function (data, textStatus, request) {
              if (data) {
                userData.type = 'client';
                localStorage.setItem('jhi-sesam', '"' + b64e(JSON.stringify(userData)) + '"');
                window.location.replace('/app/client');
              } else {
                $('.error-message').show();
              }
            },
            error: function (error) {
              if (error.status = 401) {
                $('.error-message').show();
              }
            }
          });
        }

      });

      function getFormData($form) {
        var unindexed_array = $form.serializeArray();
        var indexed_array = {};

        $.map(unindexed_array, function (n, i) {
          indexed_array[n['name']] = n['value'];
        });

        return indexed_array;
      }

      // Desktop toggle loggin menu
      var userIcon = $("#desktop-custom-menu");
      var desktopMenu = $("#desktop-navbar-right-colapse");
      var signInBox = $("#desktop-navbar-right-colapse .signin-box");
      var dropMenu = $("#desktop-navbar-right-colapse .drop-menu");
      var calculatorLink = $("#desktop-navbar-right-colapse .js-user");
      var cabinetLink = $("#desktop-navbar-right-colapse .js-cabinet");
      $("#desktop-custom-menu").on("click", function () {
        desktopMenu.toggleClass("show-menu");
        userIcon.toggleClass("active")
        if (!user) {
          signInBox.show();
          dropMenu.hide();
          $(
            "#desktop-navbar-right-colapse .signin-box #inputEmail"
          ).focus();
        } else {
          signInBox.hide();
          dropMenu.show();
          if (user.type == 'user') {
            calculatorLink.show()
            cabinetLink.hide()
          } else {
            calculatorLink.hide()
            cabinetLink.show()
          }
        }
        ;

      });

      $(".sign-out").on("click", function () {
        localStorage.removeItem('jhi-sesam');
        $('nav .user-name').text('Intră');
        user = null;
        dropMenu.hide();
      });

      $("body").on("click", function (e) {
        var $userBlock = $("#desktop-custom-menu");

        if (
          $(e.target).attr("id") != "desktop-custom-menu" &&
          !$(e.target)
            .parents("#desktop-custom-menu")
            .get(0) &&
          !$(e.target)
            .parents(".desktop-signin-menu.show-menu")
            .get(0)
        ) {
          if ($userBlock.hasClass("active")) {
            $userBlock.removeClass("active");
            $("#desktop-navbar-right-colapse").removeClass(
              "show-menu"
            );
          }
        }
      });

      $(".navbar").on("show.bs.collapse", function () {
        var actives = $(this).find(".collapse.in");
        var hasData;

        if (actives && actives.length) {
          hasData = actives.data("collapse");
          if (hasData && hasData.transitioning) return;
          var hideId = actives.attr("id");
          $("div[data-target='#" + hideId + "']").trigger("click");
          hasData || actives.data("collapse", null);
        }
      });

      var scroll_start = 0;
      var offset = 100;

      if (true) {
        $(document).scroll(function () {
          scroll_start = $(this).scrollTop();
          if (scroll_start > offset) {
            $(".header-section").addClass("navbar-scroll");
          } else {
            $(".header-section").removeClass("navbar-scroll");
          }
        });
      }

      $(".exchange-trigger").on("click", function () {
        $(".exchange-rates").toggleClass("open");
      });

      $(".js-main-nav li").hover(
        function () {
          var $nav_element = $(this)[0];
          var new_pos =
            5000 -
            $nav_element.offsetLeft -
            $nav_element.offsetWidth / 2;

          var $navbar_bottom = $(".navbar-bottom-part");

          $navbar_bottom.css(
            "background-position-x",
            "-" + new_pos + "px"
          );
          $navbar_bottom.css("background-color", "transparent");
        },
        function () {
          $(".navbar-bottom-part").css("background-color", "white");
        }
      );

      if (location.pathname !== '/') {
        $('.js-main-nav li a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active-nav-link');
        $('.nav.navbar-nav.hidden-lg.hidden-md.hidden-sm li a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active-nav-link');
        $('footer .footer-navbar.pull-left li a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active-nav-link');
      }

      //Logic for calculator on mobilele devices
      function initMobileCalculator() {
        $selector1 = $(".exchange-rates.exchange-on-mobile");
        $selector2 = $(".hidden-calculator-mobile-header ");
        var input = $(
          ".exchange-rates.exchange-on-mobile .rates__block.active .block2 .calculate__exchange .calculate__exchange-calculator input"
        );

        function bindSelector2() {
          $selector2.one("click", function () {
            $selector1.removeClass("calculator-active");
            requestAnimationFrame(bindSelector1);
          });
        }

        function bindSelector1() {
          $selector1.one("click", function () {
            $selector1.addClass("calculator-active");
            bindSelector2();
            input.focus();
          });
        }

        bindSelector1();
      }

      $(initMobileCalculator);

      // Animation of button left and button right

      //Left button menu and back
      $("#first-animation").click(function () {
        if ($("#first-animation").hasClass("open")) {
          $(".exchange-rates.exchange-on-mobile ").removeClass(
            "calculator-active"
          );
          initMobileCalculator();

          $(this)
            .removeClass("open")
            .addClass("animation");
        } else {
          $(this)
            .addClass("open")
            .removeClass("animation");
        }
      });

      //Right button to arrow and back
      $("#custom-menu").click(function () {
        if (!$(this).hasClass("opened")) {
          $("#arrow-with-line").animate(
            {
              left: "20%"
            },
            400
          );

          $("#line")
            .animate({width: "0"}, 500)
            .hide(10);
          $("#semi-box")
            .delay(500)
            .hide(1);

          setTimeout(function () {
            $("#arrow").rotate({
              angle: -45,
              animateTo: -135
            });
          }, 650);
          $(this).addClass("opened");
        } else {
          $("#arrow").rotate({
            angle: -135,
            animateTo: -45
          });

          $("#semi-box")
            .delay(500)
            .show(1);
          $("#arrow-with-line")
            .delay(650)
            .animate(
              {
                left: "-6%"
              },
              400
            );
          $("#line")
            .delay(500)
            .show(10)
            .animate({display: "block", width: "20px"}, 500);
          $("#custom-menu").removeClass("opened");
        }
      });
    }
  };

  return Public;
})();

var ClientForm = (function () {
  var Public = {
    Init: function () {
      var frontPhotoID = document.getElementById("front-photo-id");
      var backPhotoID = document.getElementById("back-photo-id");
      var previewBackID = document.getElementById("preview-backID");
      var previewFrontID = document.getElementById("preview-frontID");

      var $rightSpiner = $("#right-spiner");
      var $leftSpiner = $("#left-spiner");

      $("#new-client-form").on("submit", function (e) {
        e.preventDefault();

        var name = $("#first_name").val();
        var lastName = $("#last_name").val();
        var email = $("#email").val();
        var phone = $("#phone").val();
        var terms = $("#accept-terms").val();

        var newClientInfo = {
          firstname: name,
          lastname: lastName,
          email: email,
          terms: terms,
          phone_mobile: phone,
          id_scan_url: previewFrontID.src
        };

        if (
          previewBackID.src !==
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        ) {
          newClientInfo.id_scan_url2 = previewBackID.src;
        }

        var carPrice = document.getElementById(
          "output-rangevalue_car-price"
        );
        var lendingTime = document.getElementById(
          "input_rangevalue_landing-time"
        );
        var advantageMoney = document.getElementById(
          "changeRangeAdvantage"
        );
        var imprumutValue = document.getElementById(
          "imprumut-value"
        );
        newClientInfo.title = window.location.pathname;

        if (carPrice) {
          newClientInfo.credit_amount = carPrice.value;
          newClientInfo.loanCurrency = {
            Code: '840',
            Name: 'USD'
          }
        }

        newClientInfo.RepresentativeSupplier = 'website';

        if (lendingTime) {
          newClientInfo.period = lendingTime.value;
        }

        if (carPrice && advantageMoney) {
          newClientInfo.price = carPrice.value;
          newClientInfo.priceCurrency = {
            Code: '978',
            Name: 'EUR'
          };
          newClientInfo.loanCurrency = carPrice.value;
          newClientInfo.loanCurrency = {
            Code: '840',
            Name: 'USD'
          };
          newClientInfo.credit_amount = imprumutValue.innerHTML.match(/\d+/g).join('');
        }
        console.log(newClientInfo);
        $.ajax({
          crossDomain: true,
          type: "post",
          url: "/api/financing-applications",
          data: JSON.stringify(newClientInfo),
          contentType: "application/json; charset=utf-8",
          success: function (succes) {
            console.log(succes);
          },
          error: function (a, status, error) {
            console.log(error);
          },
          complete: function () {
            console.log("posted");
          }
        });

        $("#clientFormModal")
          .modal("show")
          .find("#modal-client-name")
          .html(lastName);
        $(this)[0].reset();

        $(".left-side .close-btn ").trigger("click");
        $(".right-side .close-btn ").trigger("click");
      });

      function uploadImage(selector, file) {
        var formData = new FormData();
        formData.append("file", file, file.name);

        $.ajax({
          url: "/api/files/upload",
          type: "POST",
          data: formData,
          cache: false,
          contentType: false,
          enctype: "multipart/form-data",
          processData: false,
          success: function (response) {
            if (selector.id === "front-photo-id") {
              $leftSpiner.css("display", "none");
              $(".left-side .close-btn").css("display", "block");
              previewFrontID.src = response;
            } else if (selector.id === "back-photo-id") {
              $rightSpiner.css("display", "none");
              $(".right-side .close-btn ").css(
                "display",
                "block"
              );
              previewBackID.src = response;
            }
          }
        });
      }

      frontPhotoID.addEventListener("change", function (e) {
        $leftSpiner.css("display", "block");
        $("#front-photo-idLabel")
          .addClass("left-preview-mode_ON")
          .removeClass("active-left-on_hover");
        uploadImage(e.currentTarget, e.target.files[0]);
      });

      backPhotoID.addEventListener("change", function (e) {
        $rightSpiner.css("display", "block");
        $("#back-photo-idLabel")
          .addClass("right-preview-mode_ON")
          .removeClass("active-right-on_hover");
        uploadImage(e.currentTarget, e.target.files[0]);
      });

      $(".left-side .close-btn ").on("click", function () {
        previewFrontID.src =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
        $("#front-photo-idLabel").removeClass("left-preview-mode_ON");
        $(".left-side .close-btn ").css("display", "none");
        frontPhotoID.value = "";
      });

      $(".right-side .close-btn ").on("click", function () {
        previewBackID.src =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
        $("#back-photo-idLabel").removeClass("right-preview-mode_ON");
        $(".right-side .close-btn ").css("display", "none");
        backPhotoID.value = "";
      });

      $("#front-photo-idLabel")
        .on("mouseenter", function () {
          $(this).addClass("active-left-on_hover");
        })
        .on("mouseleave", function () {
          $(this).removeClass("active-left-on_hover");
        });

      $("#back-photo-idLabel")
        .on("mouseenter", function () {
          $(this).addClass("active-right-on_hover");
        })
        .on("mouseleave", function () {
          $(this).removeClass("active-right-on_hover");
        });
    }
  };

  return Public;
})();

var CurrencyConverter = (function () {
  var Public = {
    EURConfig: {},
    USDConfig: {},

    Init: function (curConf) {
      Public.USDConfig = curConf.usd;
      Public.EURConfig = curConf.eur;
      var months = [];
      if (language === 'ro') {
        months = ['ianuarie', 'februarie', 'martie', 'aprilie', 'mai', 'iunie', 'iulie', 'august', 'septembrie', 'octombrie', 'noiembrie', 'decembrie'];
      } else {
        months = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];
      }
      ;

      function getWidthOfScreen() {
        return (width = window.innerWidth);
      }

      function formatDate(date) {
        var splitedDate;
        splitedDate = date.split('-');
        if (splitedDate.length === 1) {
          splitedDate = date.split('/');
          return splitedDate[1] + ' ' + months[Number(splitedDate[0]) - 1] + ' ' + splitedDate[2]
        } else {
          return splitedDate[2] + ' ' + months[Number(splitedDate[1]) - 1] + ' ' + splitedDate[0]
        }
      }

      var euroDate = formatDate(Public.EURConfig.date);
      var usdDate = formatDate(Public.USDConfig.date);
      var $linkSelector = $(".exchange-rates .exchange__block .exch");
      var $hiddenElem = $(".exchange-rates .rates__block ");
      var $dollarExchange = $(".exch.dollar__exchange ");
      var $euroExchange = $(".exch.euro__exchange ");
      var $changeTypeOfMoney = $(".type-of-money");
      var $date = $('.exchange-rates .rates__block .block1 .date');
      var $mobileDate = $('.hidden-calculator-mobile-header .date');

      $mobileDate.html(usdDate);

      $targetBlockMobile = $(
        ".exchange-rates.exchange-on-mobile .hidden-calculator-mobile .exchange__block"
      );

      windowWidth = getWidthOfScreen();


      $linkSelector.on("click", function () {

        if (windowWidth < 767) {
          if (this.getAttribute("data-exchg-type") === "euro") {
            $(this)
              .parent()
              .append($(this));
            $changeTypeOfMoney.html("&#8364;");
            $(".currency-converter-input").attr(
              "data-type",
              "euro"
            );

            $targetBlockMobile.addClass("mobile-euro-active");
            $targetBlockMobile.removeClass("mobile-dollar-active");
          } else if (
            this.getAttribute("data-exchg-type") === "dollar"
          ) {
            $(this)
              .parent()
              .append($(this));
            $changeTypeOfMoney.html("&#36;");
            $(".currency-converter-input").attr("data-type", "usd");
            $targetBlockMobile.addClass("mobile-dollar-active");
            $targetBlockMobile.removeClass("mobile-euro-active");
          }
        } else {
          if (this.getAttribute("data-exchg-type") === "euro") {
            $(this)
              .parent()
              .append($(this));
            $changeTypeOfMoney.html("&#8364;");
            $(".currency-converter-input").attr(
              "data-type",
              "euro"
            );

            if (!$euroExchange.hasClass("euro-active")) {
              $targetBlockMobile.addClass("mobile-euro-active");
              $targetBlockMobile.removeClass(
                "mobile-dollar-active"
              );

              if ($dollarExchange.hasClass("active-dollar")) {
                $dollarExchange.removeClass("active-dollar");
                $euroExchange.addClass("euro-active");
                $(".currency-converter-input").attr(
                  "data-type",
                  "euro"
                );
              } else {
                $hiddenElem.addClass("active");
                $euroExchange.addClass("euro-active");
                $changeTypeOfMoney.html("&#8364;");
                $(".currency-converter-input").attr(
                  "data-type",
                  "euro"
                );
              }
            } else {
              $hiddenElem.removeClass("active");
              $euroExchange.removeClass("euro-active");
              $changeTypeOfMoney.html("&#36;");
              $(".currency-converter-input").attr(
                "data-type",
                "usd"
              );
            }
          } else if (
            this.getAttribute("data-exchg-type") === "dollar"
          ) {
            $(this)
              .parent()
              .append($(this));
            $changeTypeOfMoney.html("&#36;");
            $(".currency-converter-input").attr("data-type", "usd");

            if (!$dollarExchange.hasClass("active-dollar")) {
              if ($euroExchange.hasClass("euro-active")) {
                $euroExchange.removeClass("euro-active");
                $dollarExchange.addClass("active-dollar");
                $(".currency-converter-input").attr(
                  "data-type",
                  "usd"
                );
              } else {
                $hiddenElem.addClass("active");
                $dollarExchange.addClass("active-dollar");
                $changeTypeOfMoney.html("&#36;");
                $(".currency-converter-input").attr(
                  "data-type",
                  "usd"
                );
              }

              $targetBlockMobile.addClass("mobile-dollar-active");
              $targetBlockMobile.removeClass(
                "mobile-euro-active"
              );
            } else {
              $dollarExchange.removeClass("active-dollar");
              $hiddenElem.removeClass("active");
              $changeTypeOfMoney.html("&#8364;");
              $(".currency-converter-input").attr(
                "data-type",
                "euro"
              );
            }
          }
        }

        $(".currency-converter-input").trigger("input");
        if ($dollarExchange.hasClass("active-dollar")) {
          $date.html(usdDate);
        } else if (($euroExchange).hasClass("euro-active")) {
          $date.html(euroDate)
        }


        if ($('.hidden-calculator-mobile .exchange__block').hasClass("mobile-euro-active")) {
          $mobileDate.html(euroDate);

        } else if ($('.hidden-calculator-mobile .exchange__block').hasClass("mobile-dollar-active")) {
          $mobileDate.html(usdDate);
        }
      });

      $(".currency-converter-input").on("input", function (event) {
        var $target = $(event.target);
        convertMoney(event.target);
      });

      var convertMoney = function (target) {
        var value = $(target).val();
        $(".currency-converter-input").val(value);
        var currency = 1;
        var result = value;
        if ($(target).attr("data-type") === "usd") {
          result = value * Public.USDConfig.paymentRate;
          $(".rate-buy .money-rate").html(
            Number(Public.USDConfig.paymentRate).toFixed(2)
          );
          $(".rate-sell .money-rate").html(
            Number(Public.USDConfig.releaseRate).toFixed(2)
          );
        } else if ($(target).attr("data-type") === "euro") {
          result = value * Public.EURConfig.paymentRate;
          $(".rate-buy .money-rate").html(
            Number(Public.EURConfig.paymentRate).toFixed(2)
          );
          $(".rate-sell .money-rate").html(
            Number(Public.EURConfig.releaseRate).toFixed(2)
          );
        }
        $(".currency-converter-result").html(Math.round(result));
      };

      function initMobileCalculator() {
        $(".exchange-on-mobile .currency-converter-input").trigger('input');
        $selector1 = $(".exchange-rates.exchange-on-mobile");
        $selector2 = $(".hidden-calculator-mobile-header ");
        var input = $(
          ".exchange-rates.exchange-on-mobile .rates__block.active .block2 .calculate__exchange .calculate__exchange-calculator input"
        );

        function bindSelector2() {
          $selector2.one("click", function () {
            $selector1.removeClass("calculator-active");
            requestAnimationFrame(bindSelector1);
          });
        }

        function bindSelector1() {
          $selector1.one("click", function () {
            $selector1.addClass("calculator-active");
            bindSelector2();
            input.focus();
          });
        }

        bindSelector1();
      }

      $(initMobileCalculator);
    }
  };

  return Public;
})();

var MapAPI = (function () {
  var Public = {
    Init: function () {
      var $mapBackground = $("#contact-us .map-area");
      chisinauBack = "/images/contact-map-chisinau-bg.png";
      chisinauBackMobile = "/images/chisinau-map-mobile.png";
      baltiBack = "/images/contact-map-balti-bg.png";
      baltiBackMobile = "/images/balti-map-mobile.png";
      var $chisinauCheck = $("#chisinau-check");
      var $baltiCheck = $("#balti-check");

      chisinauAddressUrl =
        "https://www.google.com/maps/place/Credit+Rapid/@47.0616576,28.8517453,15.3z/data=!3m1!4b1!4m5!3m4!1s0x0:0x717dcc7fd242e089!8m2!3d47.0616541!4d28.8539339";
      baltiAddressUrl =
        "https://www.google.com/maps/place/Stefan+cel+Mare+St+76,+Balti,+Moldova/@47.7593991,27.9187753,15.3z/data=!3m1!4b1!4m5!3m4!1s0x40cb67323abc6005:0xcb8aa2bc3a299c6d!8m2!3d47.7593955!4d27.920964";

      windowWidth = window.width;

      checkWindowWidth(windowWidth);

      function checkWindowWidth(windowWidth) {
        if (windowWidth <= 767) {
          if ($chisinauCheck.is(":checked")) {
            $mapBackground.css(
              "background-image",
              "url(" + chisinauBackMobile + ")"
            );
          }

          if ($baltiCheck.is(":checked")) {
            $mapBackground.css(
              "background-image",
              "url(" + baltiBackMobile + ")"
            );
          }

          $chisinauCheck.on("click", function () {
            if ($(this).is(":checked")) {
              $mapBackground.css(
                "background-image",
                "url(" + chisinauBackMobile + ")"
              );
            }
          });

          $baltiCheck.on("click", function () {
            if ($(this).is(":checked")) {
              $mapBackground.css(
                "background-image",
                "url(" + baltiBackMobile + ")"
              );
            }
          });
        } else {
          if ($chisinauCheck.is(":checked")) {
            $mapBackground.css(
              "background-image",
              "url(" + chisinauBack + ")"
            );
          }

          if ($baltiCheck.is(":checked")) {
            $mapBackground.css(
              "background-image",
              "url(" + baltiBack + ")"
            );
          }

          $chisinauCheck.on("click", function () {
            if ($(this).is(":checked")) {
              $mapBackground.css(
                "background-image",
                "url(" + chisinauBack + ")"
              );
            }
          });

          $baltiCheck.on("click", function () {
            if ($(this).is(":checked")) {
              $mapBackground.css(
                "background-image",
                "url(" + baltiBack + ")"
              );
            }
          });
        }
      }

      $(window).resize(function () {
        $windowWidth = $(window).width();
        checkWindowWidth($windowWidth);
      });

      $("#contact-us .map-area .map-right").on("click", () => {
        if ($("#chisinau-check").is(":checked")) {
          window.open(chisinauAddressUrl, "_blank");
        } else if ($("#balti-check").is(":checked")) {
          window.open(baltiAddressUrl, "_blank");
        }
      });
    }
  };

  return Public;
})();

var YoutubeAPI = (function () {
  var Public = {
    player: null,
    Init: function () {

      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/player_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      YoutubeAPI.videoThumbClickEventInit(YoutubeAPI.player);
    },
    videoThumbClickEventInit(player) {
      $(".js-play-video ").on("click", function (e) {
        e.stopPropagation();

        var link = $(this).attr("data-youtube-id");
        if (YoutubeAPI.player) {
          YoutubeAPI.player.destroy();
        }
        YoutubeAPI.initYTPlayer(link);

        var videoTitle = $(this).find('.video-info .title').text();
        var videoDuration = $(this).find('.video-info .duration').text();

        $('.main-video-info .title').html(videoTitle);
        $('.main-video-info .duration').html(videoDuration);

        $('.right-side .secondary-video.js-play-video').removeClass('playing-video');
        $(this).addClass('playing-video');

      });
    },
    initYTPlayer(id) {
      YoutubeAPI.player = new YT.Player("player", {
        playerVars: {
          controls: 1,
          autohide: 1,
          rel: 0,
          showinfo: 1,
          wmode: "opaque",
          fs: 0
        },
        videoId: id,
        events: {
          onReady: YoutubeAPI.onPlayerReady
        }
      });

      $(".album-section .video-album-viewer .main-video").addClass("player");
    },
    onPlayerReady(event) {
      event.target.playVideo();
    }
  };

  return Public;
})();

var Inphographics = (function () {
  var Public = {
    Init: function () {
      var $myElement = document.getElementById("infographics-list");
      var elementWatcher = scrollMonitor.create($myElement);
      var $myVideo = $(
        ".infographics-list .infographics-element .image-holder"
      ).find("video");

      function inScreenGrapgics() {
        if (elementWatcher.isFullyInViewport) {
          time = 0;
          $myVideo.each(function (i, el) {
            setTimeout(function () {
              el.play();
            }, time);
            time += el.duration * 1000;
          });
        }
      }

      if (elementWatcher.isFullyInViewport) {
        inScreenGrapgics();
      }

      elementWatcher.stateChange(function () {
        inScreenGrapgics();
      });
    }
  };

  return Public;
})();

var Advantages = (function () {
  var Public = {
    Init: function () {
      $(".advantage-carousel").slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        prevArrow: $(".prev-arrow"),
        nextArrow: $(".forward-arrow"),
        responsive: [
          {
            breakpoint: 5000,
            settings: "unslick"
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }
        ]
      });

      $(".advantage-item > .image-holder ").on("mouseenter", function (
        event
      ) {
        $(this)
          .find(".static-gif")
          .css("display", "none");
        $(this)
          .find(".active-on_hover")
          .css({
            opacity: "1"
          });

        $(this).css("height", "200");
        var $url = $(this)
          .find(".active-on_hover")
          .attr("src");
        var randomNumber = new Date().getTime();

        var s = $url;
        var n = s.indexOf("?");
        s = s.substring(0, n != -1 ? n : s.length);

        $(this)
          .find(".active-on_hover")
          .attr("src", s + "?v=" + randomNumber);
      });
    }
  };

  return Public;
})();

var TippyTiptool = (function () {
  var Public = {
    Init: function () {
      var tipTool1 = tippy(".tool-tip", {
        arrow: true,
        delay: 100,
        placement: "right",
        arrowType: "sharp",
        arrowtransform: "scale(5)",
        animation: "shift-toward",
        duration: "[400, 200]",
        size: "large",
        animateFill: true,
        theme: "light",
        onShow(instance) {
          // `this` inside callbacks refers to the popper element
          var content = this.querySelector(".tippy-content");
          if (language === 'ro') {
            if (instance.id === 1) {
              content.innerHTML = `<div style="width: 270px; height: 160px; background-color:#0d6cb5"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-1.png"> <div style="color: white; padding: 5px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Informații despre produse financiare, condiții de finanțare și totul despre costul creditului.</div></div>`;
            } else if (instance.id === 2) {
              content.innerHTML = `<div style="width: 270px; height: 210px; background-color:#0d6cb5; padding-top: 5px"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-2.png"> <div style="color: white; padding: 5px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Modifică data de plată a ratelor lunare, află suma pentru închiderea anticipată a creditului și găsește răspuns la orice întrebare referitor la modificarea condițiilor contractului în derulare. </div></div>`;
            } else if (instance.id === 3) {
              content.innerHTML = `<div style="width: 270px; height: 160px; background-color:#0d6cb5; padding-top: 20px"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-3.png"> <div style="color: white; padding: 10px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Află suma spre plată din ziua curentă.</div></div>`;
            } else if (instance.id === 4) {
              content.innerHTML = `<div style="width: 270px; height: 160px; background-color:#0d6cb5"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-4.png"> <div style="color: white; padding: 5px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Informații despre produse financiare, condiții de finanțare și totul despre costul creditului.</div></div>`;
            }
          } else {
            if (instance.id === 1) {
              content.innerHTML = `<div style="width: 270px; height: 160px; background-color:#0d6cb5"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-1.png"> <div style="color: white; padding: 5px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Информация о финансовых продуктах, условиях финансирования и все о стоимости кредита.</div></div>`;
            } else if (instance.id === 2) {
              content.innerHTML = `<div style="width: 270px; height: 210px; background-color:#0d6cb5; padding-top: 5px"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-2.png"> <div style="color: white; padding: 5px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Измените дату оплаты ежемесячных платежей, узнайте сумму для досрочного расторжения кредита и найдите ответ на любой вопрос, касающийся изменения условий действующего договора. </div></div>`;
            } else if (instance.id === 3) {
              content.innerHTML = `<div style="width: 270px; height: 160px; background-color:#0d6cb5; padding-top: 20px"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-3.png"> <div style="color: white; padding: 10px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Узнайте сумму платежа за текущий день.</div></div>`;
            } else if (instance.id === 4) {
              content.innerHTML = `<div style="width: 270px; height: 160px; background-color:#0d6cb5"><img width="170" height="100" src="/images/iphone10-icons/tooltip-icons/tooltip-4.png"> <div style="color: white; padding: 5px 20px; font-family: 'Rubik', sans-serif; font-size: 13px; line-height: 15px;">Информация о финансовых продуктах, условиях финансирования и все о стоимости кредита.</div></div>`;
            }
          }
          ;
        },
        onHidden() {
          initialText = "Loading";
          var content = this.querySelector(".tippy-content");
          content.innerHTML = initialText;
        },
        // prevent tooltip from displaying over button
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: true
            },
            hide: {
              enabled: true
            }
          }
        }
      });
    }
  };

  return Public;
})();

var Calculator = (function () {
  var Public = {
    DataCuGaj: {},
    DataFaraGaj: {},
    currencies: {},
    currenciesBussNeeds: {},
    DataBusiness: {},
    animationArray: [],
    carSliderItemsConfig1: [],
    carSliderItemsConfig2: [],
    months: 0,
    imprumutValue: 0,
    calculatorType: "",
    fileConfigType: "",
    euroSell: 19.25,
    euroBuy: 19.53,
    dollarSell: 16.48,
    dollarBuy: 16.79,
    PrecontratDataAuto: {},
    PrecontratDataBusinessNeeds: {},
    pageType: '',

    Init: function (
      pageName,
      calculatorConfigJsonFile1,
      calculatorConfigJsonFile2,
      carSliderArrayItemsConfig1,
      carSliderArrayItemsConfig2,
      exchangeRate,
      currencyValue
    ) {
      Public.pageType = pageName;
      Public.DataCuGaj = calculatorConfigJsonFile1;
      Public.DataFaraGaj = calculatorConfigJsonFile2;
      Public.DataBusiness = calculatorConfigJsonFile1;
      Public.currencies = calculatorConfigJsonFile2;
      Public.carSliderItemsConfig1 = carSliderArrayItemsConfig1;
      Public.carSliderItemsConfig2 = carSliderArrayItemsConfig2;
      Public.currenciesBussNeeds = exchangeRate;

      getCurrencySymbol(currencyValue);
      if (pageName === "auto") {
        Public.InitAutoCalculator();
      } else if (pageName === "business") {
        Public.InitBusinessCalculator();
      } else if (pageName === "necesitati") {
        Public.InitNecesitatiCalculator();
      } else if (pageName === "simple-auto") {
        Public.InitAutoCalculator();
      }
    },

    countDaysYear: function (countDaysCount) {
      const countDays = [];
      const today = moment();
      let currentDate,
        lastDate = today.clone();
      const todayDay = today.date();
      const daysInThisYear = [];
      for (let i = 1; i <= countDaysCount; i++) {
        currentDate = lastDate.clone();
        lastDate = today
          .clone()
          .add('months', i)
          .date(todayDay);

        if (lastDate.day() === 0) {
          lastDate.add('days', 1);
        } else if (lastDate.day() === 6) {
          lastDate.add('days', 2);
        }

        countDays[i - 1] = lastDate.diff(currentDate, 'days');
        daysInThisYear[i - 1] =
          lastDate
            .clone()
            .endOf('year')
            .diff(lastDate.clone().startOf('year'), 'days') + 1;
      }
      return [countDays, daysInThisYear];
    },

    MakeCalculus: {
      checkWhichFile: function () {
        var MYDATA;
        if (Public.fileConfigType === "cu gaj") {
          MYDATA = Public.DataCuGaj;
          Slider.animationQuantity = 4;
        } else if (Public.fileConfigType === "fara gaj") {
          MYDATA = Public.DataFaraGaj;
          Slider.animationQuantity = 3;
        } else if (Public.fileConfigType === "auto") {
          MYDATA = Public.DataBusiness;
          Slider.animationQuantity = 5;
        }
        return MYDATA;
      },

      emailPrecontractOffer: function () {
        jQuery.support.cors = true;

        $("#emailPrecontractOfferr").on("submit", e => {
          e.preventDefault();
          var email = $("#userEmail").val();
          var emailData;

          if (!jQuery.isEmptyObject(Public.PrecontratDataAuto)) {
            emailData = Public.PrecontratDataAuto;
          }

          if (
            !jQuery.isEmptyObject(
              Public.PrecontratDataBusinessNeeds
            )
          ) {
            emailData = Public.PrecontratDataBusinessNeeds;
          }

          emailData.type = $("#emailPrecontractOfferr").data("type");

          $("#emailPrecontractOfferr")
            .find("button")
            .attr("disabled", true);

          $.ajax({
            crossDomain: true,
            type: "post",
            url: "/api/generate/email/" + email,
            data: JSON.stringify(emailData),
            contentType: "application/json; charset=utf-8",
            success: function () {
              $(this)
                .find("button")
                .attr("disabled", false);
            },
            error: function (a, status, error) {
              $(this)
                .find("button")
                .attr("disabled", false);
            },
            complete: function () {
              $("#userEmail").val("");
              $("#emailModalOfferr").modal("hide");
              $(this)
                .find("button")
                .attr("disabled", false);
            }
          });
        });
      },

      convertEuroToDollar: function (euro, dollar) {
        return euro / dollar;
      },
      convertDollarToEuro: function (dollar, euro) {
        return dollar / euro;
      },

      initHiddenCalculator: function () {
        var $businessSelector = $(
          ".business-calculator .calculation-area .right-calculator .business-speedometer"
        );
        var $needsSelector = $(
          ".necesitati-calculator .calculation-area .right-calculator .necesitati-speedometer"
        );
        var $autosSelector = $(
          ".auto-calculator .calculation-area .speedometer "
        );

        $(window).resize(function () {
          if (window.innerWidth > 992) {
            if ($businessSelector.hasClass("insideModal")) {
              $businessSelector
                .removeClass("insideModal")
                .prependTo(
                  ".business-calculator .calculation-area .right-calculator"
                );
            }

            if ($needsSelector.hasClass("insideModal")) {
              $(
                "#needs-modalCalculator .modal-body .necesitati-speedometer.insideModal"
              )
                .removeClass("insideModal")
                .prependTo(
                  ".necesitati-calculator .calculation-area .right-calculator"
                );
            }

            if ($autosSelector.hasClass("insideModal")) {
              $(
                "#auto-modalCalculator .modal-body .speedometer.insideModal"
              )
                .removeClass("insideModal")
                .prependTo(
                  ".auto-calculator .calculation-area .right-calculator"
                );
            }
          }
        });

        $("button#hidden-mob-btn").on("click", e => {
          var btn = e.target;
          var $dataAttr = $(btn).data("modal-name");
          var $modal = $("#" + $dataAttr + "-modalCalculator");

          $modal.modal("show");

          $modal.on("shown.bs.modal", function () {
            if ($modal.attr("id") === "business-modalCalculator") {
              $businessSelector.prependTo(
                $modal.find(".modal-body")
              );
              $modal
                .find(".business-speedometer")
                .addClass("insideModal");
            } else if (
              $modal.attr("id") === "needs-modalCalculator"
            ) {
              $needsSelector.prependTo(
                $modal.find(".modal-body")
              );
              $modal
                .find(".necesitati-speedometer")
                .addClass("insideModal");
            } else if (
              $modal.attr("id") === "auto-modalCalculator"
            ) {
              $autosSelector.prependTo(
                $modal.find(".modal-body")
              );
              $modal
                .find(".speedometer ")
                .addClass("insideModal");
            }
          });

          $modal.on("hidden.bs.modal", function () {
            if ($modal.attr("id") === "business-modalCalculator") {
            } else if (
              $modal.attr("id") === "needs-modalCalculator"
            ) {
            } else if (
              $modal.attr("id") === "auto-modalCalculator"
            ) {
            }
          });
        });
      },

      numberWithCommas: function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },

      //Init html with data
      initDataDisplay: function () {
        MYDATA = Public.MakeCalculus.checkWhichFile();
        if (MYDATA.init_data) {
          imprumutValue = MYDATA.init_data.imprumut;
        }
        months = MYDATA.init_data.termenul_imprumutului;

        var $imprumutMinVal = $(
          ".calculation-area .calculator .the-car-cost .min-amount h5"
        );
        var $imprumutMaxVal = $(
          " .calculation-area .calculator .the-car-cost .max-amount h5"
        );
        var $imprumutRangeInput = $(" #output-rangevalue_car-price ");
        var $imprumutBoxInput = $("#rangevalue_car-price");

        var $termenImprumutMinVal = $(
          " .calculation-area .calculator .deadline-of-lending .min-amount h5"
        );
        var $termenImprumutMaxVal = $(
          " .calculation-area .calculator .deadline-of-lending .max-amount h5"
        );
        var $termenImprumutRangeInput = $(
          "#input_rangevalue_landing-time"
        );
        var $termenImprumutBoxInput = $(
          "#output_rangevalue_landing-time"
        );
        getCurrencySymbol(MYDATA.init_data.currency && MYDATA.init_data.currency.values ? MYDATA.init_data.currency.values : '$');
        $imprumutMinVal.html(MYDATA.init_data.suma_imprumut_min + (currencyWithSpace === ' L' ? ' MDL' : currencyWithSpace));
        $imprumutMaxVal.html(MYDATA.init_data.suma_imprumut_max + (currencyWithSpace === ' L' ? ' MDL' : currencyWithSpace));
        $imprumutRangeInput.attr({
          min: MYDATA.init_data.suma_imprumut_min,
          max: MYDATA.init_data.suma_imprumut_max
        });
        $imprumutRangeInput.val(MYDATA.init_data.imprumut);
        if (Public.pageType === 'auto' || Public.pageType === 'simple-auto') {
          $imprumutBoxInput.val(MYDATA.init_data.imprumut + currencyWithNoSpace);
        } else {
          $imprumutBoxInput.val(MYDATA.init_data.imprumut + (currencyWithSpace === ' L' ? ' MDL' : currencyWithSpace));
        }
        if (language === 'ro') {
          $termenImprumutMinVal.html(
            MYDATA.init_data.termen_imprumut_min + " luni"
          );
          $termenImprumutMaxVal.html(
            MYDATA.init_data.termen_imprumut_max + " luni"
          );
        } else {
          $termenImprumutMinVal.html(
            MYDATA.init_data.termen_imprumut_min + " месяцев"
          );
          $termenImprumutMaxVal.html(
            MYDATA.init_data.termen_imprumut_max + " месяцев"
          );
        }
        ;
        $termenImprumutRangeInput.attr({
          min: MYDATA.init_data.termen_imprumut_min,
          max: MYDATA.init_data.termen_imprumut_max
        });
        $("#footer-termen-imprumut").html(months);

        $("#footer-imprumut").html(
          Calculator.MakeCalculus.numberWithCommas(
            Number(imprumutValue)
          )
        );
        $("#footer-comision-de-acordare").html();
        $("#footer-rata-dobinda-fixa").html();
        $("#footer-comision-lunar").html();
        $("#total-loan-cost").html();

        $termenImprumutRangeInput.val(
          MYDATA.init_data.termenul_imprumutului
        );
        $termenImprumutBoxInput.val(
          MYDATA.init_data.termenul_imprumutului
        );
      },

      // Init only once input on business calculator or necesitati calculator
      initCalculatorInputs: function () {
        MYDATA = Public.MakeCalculus.checkWhichFile();
        imprumutValue = MYDATA.init_data.imprumut;
        months = MYDATA.init_data.termenul_imprumutului;

        var $imprumutRangeInput = $(" #output-rangevalue_car-price ");
        var $imprumutBoxInput = $("#rangevalue_car-price");
        var $termenImprumutRangeInput = $(
          "#input_rangevalue_landing-time"
        );
        var $termenImprumutBoxInput = $(
          "#output_rangevalue_landing-time"
        );

        $imprumutRangeInput.on("input", function () {
          imprumutValue = parseInt(this.value);
          if (Public.pageType === 'auto' || Public.pageType === 'simple-auto') {
            $imprumutBoxInput.val(imprumutValue + currencyWithNoSpace);
          } else {
            $imprumutBoxInput.val(imprumutValue + (currencyWithSpace === ' L' ? ' MDL' : currencyWithSpace));
          }
          $("#footer-imprumut").html(imprumutValue);
        });

        $imprumutBoxInput.keypress(e => {
          return Public.MakeCalculus.allowOnlyNumbers(e);
        });

        $termenImprumutBoxInput.keypress(e => {
          return Public.MakeCalculus.allowOnlyNumbers(e);
        });

        $imprumutRangeInput.on("change", function () {
          Public.MakeCalculus.checkUserInput();
        });

        $imprumutBoxInput.on("input", function () {
          imprumutValue = parseInt(this.value.split(" ")[0]);
          if (!isNaN(imprumutValue)) {
            if (
              imprumutValue < MYDATA.init_data.suma_imprumut_min
            ) {
              imprumutValue = MYDATA.init_data.suma_imprumut_min;
            } else if (
              imprumutValue > MYDATA.init_data.suma_imprumut_max
            ) {
              this.value =
                MYDATA.init_data.suma_imprumut_max + currencyWithSpace;
              imprumutValue = MYDATA.init_data.suma_imprumut_max;
            }
          } else {
            imprumutValue = MYDATA.init_data.suma_imprumut_min;
          }
          $imprumutRangeInput.val(imprumutValue + currencyWithSpace);
          $("#footer-imprumut").html(imprumutValue);
        });
        $imprumutBoxInput.on("change", function () {
          this.value = imprumutValue;
          $(this).val(imprumutValue + currencyWithSpace);
          Public.MakeCalculus.checkUserInput();
        });

        $termenImprumutRangeInput.on("input", function () {
          months = parseInt(this.value);
          $termenImprumutBoxInput.val(months);
          $("#footer-termen-imprumut").html(months);
        });
        $termenImprumutRangeInput.on("change", function () {
          Public.MakeCalculus.checkUserInput();
        });

        $termenImprumutBoxInput.on("input", function () {
          months = parseInt(this.value.split(" ")[0]);
          var roundVar = Math.round(months / 5) * 5;
          var myfunction = Public.MakeCalculus.checkMonthsMinMaxValue(
            months
          );
          Public.MakeCalculus.checkMonthsMinMaxValue(months);
          months = myfunction;
          if (months % 5 === 0) {
            Public.MakeCalculus.checkMonthsMinMaxValue(months);
          } else {
            var a = Public.MakeCalculus.checkMonthsMinMaxValue(
              roundVar
            );
            months = a;
          }
          $termenImprumutRangeInput.val(months).val();
          $("#footer-termen-imprumut").html(months);
        });
        $termenImprumutBoxInput.on("change", function () {
          this.value = months;
          Public.MakeCalculus.checkUserInput();
        });
        $imprumutRangeInput.trigger("input");
        $termenImprumutRangeInput.trigger("input");
      },

      checkMonthsMinMaxValue: function (months) {
        MYDATA = Public.MakeCalculus.checkWhichFile();

        if (months) {
          if (months < MYDATA.init_data.termen_imprumut_min) {
            months = MYDATA.init_data.termen_imprumut_min;
          } else if (months > MYDATA.init_data.termen_imprumut_max) {
            this.value = MYDATA.init_data.termen_imprumut_max;
            months = MYDATA.init_data.termen_imprumut_max;
          }
        } else {
          months = MYDATA.init_data.termen_imprumut_min;
        }

        return months;
      },
      returnPosition: function (
        angle,
        radiusX,
        radiusY,
        xPosition,
        yPosition
      ) {
        var x = radiusX * Math.cos(angle) + xPosition;
        var y = radiusY * Math.sin(angle) + yPosition;
        return [x, y];
      },
      calculateGrantingCommission(
        moneyLoan,
        grantingCommissionPercent,
        grantingCommissionMinValue,
        currentObj
      ) {
        var grantingCommissionVal =
          moneyLoan * grantingCommissionPercent;
        if (grantingCommissionVal < grantingCommissionMinValue) {
          grantingCommissionVal = grantingCommissionMinValue;
        }

        if (currentObj && currentObj.comision_de_acordare_luni) {
          let grantingCents = ( (grantingCommissionVal * 100 ) % currentObj.comision_de_acordare_luni ) / 100;
          grantingCommissionVal = grantingCommissionVal - grantingCents;
        }

        return grantingCommissionVal;
      },

      calculateMonthlyCommission(loan) {
        let monthlyCommissionVal = 0;
        if (MYDATA.details.comision_lunar_type && MYDATA.details.comision_lunar_type.values === 'percent') {
          monthlyCommissionVal = round2(loan *
            MYDATA.details.comision_lunar);
          if (monthlyCommissionVal < MYDATA.details.comision_lunar_min) {
            monthlyCommissionVal = MYDATA.details.comision_lunar_min;
          }
          if (monthlyCommissionVal > MYDATA.details.comision_lunar_max) {
            monthlyCommissionVal = MYDATA.details.comision_lunar_max;
          }
        } else {
          monthlyCommissionVal = MYDATA.details.comision_lunar;
        }

        return monthlyCommissionVal;
      },

      calculateComision: function (
        typeOfMounthlyComisionVal,
        moneyLoan,
        totalMonths,
        monthlyCommission,
        grantingCommissionMinValue,
        grantingCommissionPercent,
        monthlyCommissionMaxVal
      ) {
        var monthlyCommissionVal = Public.MakeCalculus.calculateMonthlyCommission(moneyLoan);
        var grantingCommissionVal = Public.MakeCalculus.calculateGrantingCommission(
          moneyLoan,
          grantingCommissionPercent,
          grantingCommissionMinValue
        );
        return (
          totalMonths * monthlyCommissionVal + grantingCommissionVal
        );
      },
      calculateMonthlyRate: function (
        totalMonths,
        interestRate,
        moneyLoan,
        monthlyCommission,
        monthlyCommissionMaxVal
      ) {
        monthlyCommissionVal = 0;
        monthlyCommissionVal = moneyLoan * monthlyCommission;

        if (monthlyCommissionMaxVal === undefined) {
          monthlyCommissionVal = monthlyCommission;
        }

        if (monthlyCommissionVal > monthlyCommissionMaxVal) {
          monthlyCommissionVal = monthlyCommissionMaxVal;
        }

        return (
          (moneyLoan *
            ((interestRate / 12) *
              Math.pow(1 + interestRate / 12, totalMonths))) /
          (Math.pow(1 + interestRate / 12, totalMonths) - 1) +
          monthlyCommissionVal
        );
      },
      calculateTotalAmount: function (
        monthlyRate,
        totalMonths,
        grantingCommissionPercent,
        grantingCommissionMinValue,
        moneyLoan
      ) {
        var grantingCommissionVal =
          moneyLoan * grantingCommissionPercent;

        if (grantingCommissionVal < grantingCommissionMinValue) {
          grantingCommissionVal = grantingCommissionMinValue;
        }
        return monthlyRate * totalMonths + grantingCommissionVal;
      },
      initSpeedometerCanvasNecesitati: function (
        comisionValue,
        dobindaValue,
        imprumutValue,
        rataLunaraValue,
        totalValue
      ) {
        var lBaseCanvas = $("#necesitati-canvas")[0];
        var gBaseCanvasStage = new createjs.Stage(lBaseCanvas);

        var $sel1 = $("#needs-modalCalculator").find("#dobinda-value");
        var $sel2 = $("#needs-modalCalculator").find("#imprumut-value");
        var $sel3 = $("#needs-modalCalculator").find("#comision-value");
        // Lets make sure that we can see our canvas DIV
        $(".canvas-speedometer").show();

        var radius = 238;
        var maxNumbOfArcs = 22;
        var numbOfArcsComision;
        var numbOfArcsDobinda;
        var startAngle1 = 0.604 * Math.PI;
        var endAngle1 = 0.694 * Math.PI;
        var startAngle2 = 0.704 * Math.PI;
        var endAngle2 = 0.794 * Math.PI;
        var startAngle3 = 0.804 * Math.PI;
        var endAngle3 = 0.894 * Math.PI;
        var windowWidth = window.innerWidth;

        numbOfArcsComision =
          (comisionValue * maxNumbOfArcs) / imprumutValue;
        numbOfArcsDobinda =
          (dobindaValue * maxNumbOfArcs) / imprumutValue;

        if (numbOfArcsComision > maxNumbOfArcs) {
          numbOfArcsComision = maxNumbOfArcs;
        }

        function createNewArcsArr(
          maxNumbOfArcs,
          startAngle1,
          endAngle1,
          color
        ) {
          var varName = "arc";
          var arr = [];

          for (var i = 0; i < 22; i++) {
            arr[varName + i] = new createjs.Shape().set({
              x: 300,
              y: 300
            });
            if (i > maxNumbOfArcs) {
              color = "#c1c8bb";
            }
            arr[varName + i].graphics.s(color).ss(2); // or ss for strokeStyle
            arr[varName + i].graphics.arc(
              0,
              0,
              radius - i * 4.2,
              startAngle1,
              endAngle1,
              false
            );
            gBaseCanvasStage.addChild(arr[varName + i]);
            gBaseCanvasStage.update();
          }
        }

        function drawGrayArcs() {
          var startAngle = [
            0.91,
            1.01,
            1.11,
            1.21,
            1.31,
            1.41,
            1.504,
            1.604,
            1.704,
            1.804,
            1.904,
            2.004,
            2.104,
            2.204,
            2.304,
            2.404,
            2.504,
            2.604,
            2.704,
            2.804,
            2.904
          ];
          var endAngle = [
            0.995,
            1.095,
            1.195,
            1.295,
            1.392,
            1.492,
            1.594,
            1.694,
            1.794,
            1.894,
            1.994,
            2.094,
            2.194,
            2.294,
            2.394,
            2.494,
            2.594,
            2.694,
            2.794,
            2.894,
            2.994
          ];

          for (var el = 0; el < 20; el++) {
            var arcName = "arc";
            var arrOfArcs = [];

            for (i = 0; i < 10; i++) {
              arrOfArcs[arcName + i] = new createjs.Shape().set({
                x: 300,
                y: 300
              });
              arrOfArcs[arcName + i].graphics.s("#c1c8bb").ss(1); // or ss for strokeStyle #c1c8bb
              arrOfArcs[arcName + i].graphics.arc(
                0,
                0,
                195 - i * 9.2,
                startAngle[el] * Math.PI,
                endAngle[el] * Math.PI,
                false
              );
              gBaseCanvasStage.addChild(arrOfArcs[arcName + i]);
              gBaseCanvasStage.update();
            }
          }
        }

        drawGrayArcs();

        var img = new Image();
        img.src = "/images/calculator-rounded-bacground-shadow.png";

        var bitmap = new createjs.Bitmap(img);

        bitmap.x = 238;
        bitmap.y = 245;
        gBaseCanvasStage.addChild(bitmap);
        gBaseCanvasStage.update();
        createjs.Ticker.setFPS(60); // Setting the FPS
        createjs.Ticker.addEventListener("tick", gBaseCanvasStage);

        var backgroundOfImprumutDobindaComision = new createjs.Shape();
        backgroundOfImprumutDobindaComision.graphics.beginFill(
          "#efefef"
        ); //s('#efefef').ss(1);
        backgroundOfImprumutDobindaComision.graphics
          .moveTo(25, 386)
          .lineTo(161, 347)
          .lineTo(180, 388)
          .lineTo(203, 413)
          .lineTo(250, 438)
          .lineTo(215, 559)
          .lineTo(25, 386); //lineTo(404, 451)
        gBaseCanvasStage.addChild(backgroundOfImprumutDobindaComision);
        gBaseCanvasStage.update();

        createNewArcsArr(
          numbOfArcsComision,
          startAngle1,
          endAngle1,
          "#f98e3d"
        );
        createNewArcsArr(
          numbOfArcsDobinda,
          startAngle2,
          endAngle2,
          "#00b7f4"
        );
        createNewArcsArr(
          maxNumbOfArcs,
          startAngle3,
          endAngle3,
          "#0076bb"
        );

        var polygonBlue = new createjs.Shape();
        polygonBlue.graphics.beginFill("#efefef"); //#efefef beginFill('#efefef') s('#000')
        polygonBlue.graphics
          .moveTo(104, 440)
          .lineTo(180, 386)
          .lineTo(173, 376)
          .lineTo(106, 409)
          .lineTo(106, 436);
        gBaseCanvasStage.addChild(polygonBlue);
        gBaseCanvasStage.update();

        var polygonRed = new createjs.Shape();
        polygonRed.graphics.beginFill("#efefef");
        polygonRed.graphics
          .moveTo(158, 492)
          .lineTo(213, 420)
          .lineTo(202, 410)
          .lineTo(152, 467)
          .lineTo(158, 492);
        gBaseCanvasStage.addChild(polygonRed);
        gBaseCanvasStage.update();

        var polygonOrange = new createjs.Shape();
        polygonOrange.graphics.beginFill("#efefef");
        polygonOrange.graphics
          .moveTo(224, 526)
          .lineTo(254, 441)
          .lineTo(242, 437)
          .lineTo(207, 502)
          .lineTo(224, 526);
        gBaseCanvasStage.addChild(polygonOrange);
        gBaseCanvasStage.update();

        var innerArcMonthlyRate = new createjs.Shape().set({
          x: 300,
          y: 300
        });
        innerArcMonthlyRate.graphics.s("#c1c8bb").ss(1); // or ss for strokeStyle #c1c8bb
        innerArcMonthlyRate.graphics.arc(
          0,
          0,
          177,
          2.304 * Math.PI,
          2.594 * Math.PI,
          false
        ); // or ss for strokeStyle #c1c8bb
        gBaseCanvasStage.addChild(innerArcMonthlyRate);
        gBaseCanvasStage.update();

        var outerArcMonthlyRate = new createjs.Shape().set({
          x: 300,
          y: 300
        });
        outerArcMonthlyRate.graphics.s("#c1c8bb").ss(1); // or ss for strokeStyle #c1c8bb
        outerArcMonthlyRate.graphics.arc(
          0,
          0,
          238,
          2.337 * Math.PI,
          2.598 * Math.PI,
          false
        ); // or ss for strokeStyle #c1c8bb
        gBaseCanvasStage.addChild(outerArcMonthlyRate);
        gBaseCanvasStage.update();

        var leftLineMonthlyRate = new createjs.Shape();
        leftLineMonthlyRate.graphics.s("#c1c8bb").ss(1);
        leftLineMonthlyRate.graphics.moveTo(229, 528).lineTo(248, 470); //.lineTo(248, 470); //lineTo(248, 470) moveTo(251.471).lineTo(229, 529).lineTo(248, 470)
        gBaseCanvasStage.addChild(leftLineMonthlyRate);
        gBaseCanvasStage.update();

        var rightLineMonthlyRate = new createjs.Shape();
        rightLineMonthlyRate.graphics.s("#c1c8bb").ss(1);
        rightLineMonthlyRate.graphics.moveTo(402, 444).lineTo(427, 479);
        gBaseCanvasStage.addChild(rightLineMonthlyRate);
        gBaseCanvasStage.update();

        var rightArcMonthlyRate = new createjs.Shape().set({
          x: 409.2,
          y: 488.4
        });
        rightArcMonthlyRate.graphics.s("#c1c8bb").ss(1); // or ss for strokeStyle #c1c8bb
        rightArcMonthlyRate.graphics.arc(
          0,
          0,
          20,
          1.8 * Math.PI,
          0.4 * Math.PI,
          false
        ); // or ss for strokeStyle #c1c8bb
        gBaseCanvasStage.addChild(rightArcMonthlyRate);
        gBaseCanvasStage.update();

        var polygonMonthlyRateBackground = new createjs.Shape();
        polygonMonthlyRateBackground.graphics.beginFill("#efefef"); //s('#ff0000').ss(1);
        polygonMonthlyRateBackground.graphics
          .moveTo(406, 451)
          .lineTo(330, 481)
          .lineTo(247.5, 476)
          .lineTo(236, 520)
          .lineTo(421, 474)
          .lineTo(406, 451); //lineTo(404, 451)
        gBaseCanvasStage.addChild(polygonMonthlyRateBackground);
        gBaseCanvasStage.update();

        function updateTxtValueAndPositionCanvas(
          comisionValue,
          dobindaValue,
          imprumutValue,
          rataLunaraValue,
          totalValue
        ) {
          dobindaValue = Number(dobindaValue);
          imprumutValue = Number(imprumutValue);
          rataLunaraValue = Number(rataLunaraValue);
          totalValue = Number(totalValue);
          comisionValue = Number(comisionValue);

          $sel1.html(dobindaValue.toFixed(0) + currencyWithSpace);
          $sel2.html(imprumutValue.toFixed(0) + currencyWithSpace);
          $sel3.html(comisionValue.toFixed(0) + currencyWithSpace);
          if (windowWidth < 992) {
            var txtComisionTitle;
            if (language === 'ro') {
              txtComisionTitle = new createjs.Text(
                "comision",
                "13px Rubik",
                "#f98e3d"
              );
            } else {
              txtComisionTitle = new createjs.Text(
                "комиссия",
                "13px Rubik",
                "#f98e3d"
              );
            }
            ;

            txtComisionTitle.x = 212;
            txtComisionTitle.y = 505;
            txtComisionTitle.rotation = -70;
            gBaseCanvasStage.addChild(txtComisionTitle);
            gBaseCanvasStage.update();
            var txtDobindaTitle;
            if (language === 'ro') {
              txtDobindaTitle = new createjs.Text(
                "dobândă",
                "13px Rubik",
                "#00b7f4"
              );
            } else {
              txtDobindaTitle = new createjs.Text(
                "проценты",
                "13px Rubik",
                "#00b7f4"
              );
            }
            ;
            txtDobindaTitle.x = 156;
            txtDobindaTitle.y = 470;
            txtDobindaTitle.rotation = -52;
            gBaseCanvasStage.addChild(txtDobindaTitle);
            gBaseCanvasStage.update();
            var txtImprumutTitle
            if (language === 'ro') {
              txtImprumutTitle = new createjs.Text(
                "Credit",
                "13px Rubik",
                "#0076bb"
              );
            } else {
              txtImprumutTitle = new createjs.Text(
                "Кредит",
                "13px Rubik",
                "#0076bb"
              );
            }
            ;
            txtImprumutTitle.x = 107;
            txtImprumutTitle.y = 417;
            txtImprumutTitle.rotation = -32;
            gBaseCanvasStage.addChild(txtImprumutTitle);
            gBaseCanvasStage.update();
            var txtTotalDeRambursat;
            if (language === 'ro') {
              txtTotalDeRambursat = new createjs.Text(
                "Total de rambursat",
                "14px Rubik",
                "#000"
              )
            } else {
              txtTotalDeRambursat = new createjs.Text(
                "Подлежит погашению",
                "12px Rubik",
                "#000"
              )
            }

            txtTotalDeRambursat.x = 245;
            txtTotalDeRambursat.y = 319;
            gBaseCanvasStage.addChild(txtTotalDeRambursat);
            gBaseCanvasStage.update();

            totalValue = Public.MakeCalculus.numberWithCommas(
              totalValue
            );

            var txtTotalDeRambursatValue = new createjs.Text(
              totalValue + currencyWithNoSpace,
              "24px Rubik",
              "#000"
            );
            txtTotalDeRambursatValue.x = currencyWithNoSpace !== 'MDL' ? 270 : 250;
            txtTotalDeRambursatValue.y = 289;
            gBaseCanvasStage.addChild(txtTotalDeRambursatValue);
            gBaseCanvasStage.update();
            var txtRataLunara;
            if (language === 'ro') {
              txtRataLunara = new createjs.Text(
                "Rata lunară ",
                "14px Rubik",
                "#000"
              );
            } else {
              txtRataLunara = new createjs.Text(
                "Еж. оплата ",
                "14px Rubik",
                "#000"
              );
            }
            ;

            txtRataLunara.x = 330;
            txtRataLunara.y = 505;
            txtRataLunara.rotation = -19;
            gBaseCanvasStage.addChild(txtRataLunara);
            gBaseCanvasStage.update();

            rataLunaraValue = Public.MakeCalculus.numberWithCommas(
              rataLunaraValue
            );

            var txtRataLunaraValue = new createjs.Text(
              rataLunaraValue + currencyWithNoSpace,
              "24px Rubik",
              "#000"
            );
            txtRataLunaraValue.x = currencyWithNoSpace !== 'MDL' ? 258 : 238;
            txtRataLunaraValue.y = 495;
            txtRataLunaraValue.rotation = 3;
            gBaseCanvasStage.addChild(txtRataLunaraValue);
            gBaseCanvasStage.update();
          } else {
            var txtComisionTitle
            if (language === 'ro') {
              txtComisionTitle = new createjs.Text(
                "comision",
                "14px Rubik",
                "#f98e3d"
              );
            } else {
              txtComisionTitle = new createjs.Text(
                "комиссия",
                "14px Rubik",
                "#f98e3d"
              );
            }
            ;

            txtComisionTitle.x = 212;
            txtComisionTitle.y = 505;
            txtComisionTitle.rotation = -70;
            gBaseCanvasStage.addChild(txtComisionTitle);
            gBaseCanvasStage.update();

            comisionValue = Public.MakeCalculus.numberWithCommas(
              comisionValue
            );

            var txtComisionValue = new createjs.Text(
              comisionValue + currencyWithNoSpace,
              "14px Rubik",
              "#f98e3d"
            );
            txtComisionValue.x = 162;
            txtComisionValue.y = 500;
            txtComisionValue.rotation = 27;
            gBaseCanvasStage.addChild(txtComisionValue);
            gBaseCanvasStage.update();
            var txtDobindaTitle;
            if (language === 'ro') {
              txtDobindaTitle = new createjs.Text(
                "dobândă",
                "14px Rubik",
                "#00b7f4"
              );
            } else {
              txtDobindaTitle = new createjs.Text(
                "проценты",
                "14px Rubik",
                "#00b7f4"
              );
            }
            ;

            txtDobindaTitle.x = 156;
            txtDobindaTitle.y = 470;
            txtDobindaTitle.rotation = -52;
            gBaseCanvasStage.addChild(txtDobindaTitle);
            gBaseCanvasStage.update();

            dobindaValue = Public.MakeCalculus.numberWithCommas(
              dobindaValue
            );

            var txtDobindaValue = new createjs.Text(
              dobindaValue + currencyWithNoSpace,
              "14px Rubik",
              "#00b7f4"
            );
            txtDobindaValue.x = 105;
            txtDobindaValue.y = 445;
            txtDobindaValue.rotation = 47;
            gBaseCanvasStage.addChild(txtDobindaValue);
            gBaseCanvasStage.update();
            var txtImprumutTitle;
            if (language === 'ro') {
              txtImprumutTitle = new createjs.Text(
                "Creditul",
                "14px Rubik",
                "#0076bb"
              );
            } else {
              txtImprumutTitle = new createjs.Text(
                "Кредит",
                "14px Rubik",
                "#0076bb"
              );
            }
            ;

            txtImprumutTitle.x = 107;
            txtImprumutTitle.y = 417;
            txtImprumutTitle.rotation = -32;
            gBaseCanvasStage.addChild(txtImprumutTitle);
            gBaseCanvasStage.update();

            imprumutValue = Public.MakeCalculus.numberWithCommas(
              imprumutValue
            );

            var txtImprumutValue = new createjs.Text(
              imprumutValue + currencyWithNoSpace,
              "14px Rubik",
              "#0076bb"
            );
            txtImprumutValue.x = 72;
            txtImprumutValue.y = 379;
            txtImprumutValue.rotation = 67;
            gBaseCanvasStage.addChild(txtImprumutValue);
            gBaseCanvasStage.update();
            var txtTotalDeRambursat;
            if (language === 'ro') {
              txtTotalDeRambursat = new createjs.Text(
                "Total de rambursat",
                "14px Rubik",
                "#000"
              );
            } else {
              txtTotalDeRambursat = new createjs.Text(
                "Подлежит погашению",
                "12px Rubik",
                "#000"
              );
            }
            ;

            txtTotalDeRambursat.x = 245;
            txtTotalDeRambursat.y = 319;
            gBaseCanvasStage.addChild(txtTotalDeRambursat);
            gBaseCanvasStage.update();

            totalValue = Public.MakeCalculus.numberWithCommas(
              totalValue
            );

            var txtTotalDeRambursatValue = new createjs.Text(
              totalValue + currencyWithNoSpace,
              "24px Rubik",
              "#000"
            );
            txtTotalDeRambursatValue.x = currencyWithNoSpace !== 'MDL' ? 270 : 250;
            txtTotalDeRambursatValue.y = 289;
            gBaseCanvasStage.addChild(txtTotalDeRambursatValue);
            gBaseCanvasStage.update();
            var txtRataLunara;
            if (language === 'ro') {
              txtRataLunara = new createjs.Text(
                "Rata lunară ",
                "14px Rubik",
                "#000"
              );
            } else {
              txtRataLunara = new createjs.Text(
                "Еж. оплата ",
                "14px Rubik",
                "#000"
              );
            }
            ;

            txtRataLunara.x = 330;
            txtRataLunara.y = 505;
            txtRataLunara.rotation = -19;
            gBaseCanvasStage.addChild(txtRataLunara);
            gBaseCanvasStage.update();

            rataLunaraValue = Public.MakeCalculus.numberWithCommas(
              rataLunaraValue
            );
            var txtRataLunaraValue = new createjs.Text(
              rataLunaraValue + currencyWithNoSpace,
              "500 18px Rubik",
              "#000"
            );
            txtRataLunaraValue.x = currencyWithNoSpace !== 'MDL' ? 258 : 238;
            txtRataLunaraValue.y = 500;
            txtRataLunaraValue.rotation = 3;
            gBaseCanvasStage.addChild(txtRataLunaraValue);
            gBaseCanvasStage.update();
          }
        }

        updateTxtValueAndPositionCanvas(
          comisionValue.toFixed(0),
          dobindaValue.toFixed(0),
          imprumutValue.toFixed(0),
          rataLunaraValue.toFixed(0),
          totalValue.toFixed(0)
        );

        if (windowWidth < 992) {
          gBaseCanvasStage.scaleX = 0.8;
          gBaseCanvasStage.scaleY = 0.8;
          gBaseCanvasStage.update();
          Public.MakeCalculus.drawingModalCalc(
            comisionValue,
            dobindaValue,
            imprumutValue,
            Public.MakeCalculus.calculateMaxValueOfCalculator(),
            "needs-modalCalculator"
          );
        }
      },
      initSpeedometerCanvasBusiness: function (
        comisionValue,
        dobindaValue,
        imprumutValue,
        rataLunaraValue,
        totalValue,
        maxValuSpeedometer
      ) {
        var lBaseCanvas = $("#demoCanvas")[0];
        var gBaseCanvasStage = new createjs.Stage(lBaseCanvas);
        var startAngle = 0.65 * Math.PI;
        var endAngle = 0.33 * Math.PI;
        var dobinda,
          imprumut,
          comision,
          endComisionValue,
          endDobindaValue,
          endImprumutValue,
          positionOfComisionValue,
          positionOfDobindaValue,
          positionOfImprumutValue;
        var imprumutMax = maxValuSpeedometer;
        var radius = 175;
        var invisibileRadiusX = 215;
        var invisibileRadiusY = 210;
        var xCoordinate = 300;
        var yCoordinate = 300;
        var circleLenght = 5.2777; // Length of circle - area unused 6.28 - 1.0048
        var windowWidth = window.innerWidth;

        var $sel1 = $("#business-modalCalculator").find(
          "#dobinda-value"
        );
        var $sel2 = $("#business-modalCalculator").find(
          "#imprumut-value"
        );
        var $sel3 = $("#business-modalCalculator").find(
          "#comision-value"
        );

        var img = new Image();
        var img1 = new Image();

        if (!img.src === '/images/calculator-shadow-business.png') {
          img.src = "/images/calculator-shadow-business.png";
        }

        if (!img1.src === '/images/calculator-rounded-bacground-shadow.png') {
          img1.src = "/images/calculator-rounded-bacground-shadow.png";
        }


        var bitmap = new createjs.Bitmap(img);
        var bitmap1 = new createjs.Bitmap(img1);

        bitmap.x = 220;
        bitmap.y = 369;
        bitmap.scaleX = 0.22;
        bitmap.scaleY = 0.22;
        bitmap1.x = 234;
        bitmap1.y = 240;

        gBaseCanvasStage.addChild(bitmap, bitmap1);
        gBaseCanvasStage.update();

        // Lets make sure that we can see our canvas DIV
        $(".canvas-speedometer").show();

        createjs.Ticker.setFPS(60); // Setting the FPS
        createjs.Ticker.addEventListener("tick", gBaseCanvasStage);

        // Add css after video Element is created in html

        var invisibleElipse = new createjs.Shape().set({
          x: 85,
          y: 90
        });
        invisibleElipse.graphics.s("#000").ss(0.0001); // or ss for strokeStyle
        invisibleElipse.graphics.drawEllipse(
          0,
          0,
          invisibileRadiusX * 2,
          invisibileRadiusY * 2
        );
        gBaseCanvasStage.addChild(invisibleElipse);
        gBaseCanvasStage.update();

        function drawArcs(
          comisionValue,
          dobindaValue,
          imprumutValue,
          rataLunaraValue,
          totalValue
        ) {
          var minValueOfArcCommission = 0.15; // comision
          var minValueOfArcInterestRate = 0.25; // dobinda
          var minValueOfArcLoan = 0.45; // imprumut
          comisionValue = Number(comisionValue);
          dobindaValue = Number(dobindaValue);
          rataLunaraValue = Number(rataLunaraValue);
          totalValue = Number(totalValue);
          imprumutValue = Number(imprumutValue);

          $sel1.html(dobindaValue.toFixed(0) + currencyWithSpace);
          $sel2.html(imprumutValue.toFixed(0) + currencyWithSpace);
          $sel3.html(comisionValue.toFixed(0) + currencyWithSpace);

          dobinda = (dobindaValue * circleLenght) / imprumutMax;
          imprumut = (imprumutValue * circleLenght) / imprumutMax;
          comision = (comisionValue * circleLenght) / imprumutMax;

          if (dobinda < minValueOfArcInterestRate)
            dobinda = minValueOfArcInterestRate;
          if (imprumut < minValueOfArcLoan)
            imprumut = minValueOfArcLoan;
          if (comision < minValueOfArcCommission)
            comision = minValueOfArcCommission;

          endComisionValue = startAngle + comision;
          endDobindaValue = endComisionValue + dobinda;
          endImprumutValue = endDobindaValue + imprumut;

          positionOfComisionValue = Public.MakeCalculus.returnPosition(
            endComisionValue,
            invisibileRadiusX,
            invisibileRadiusY,
            xCoordinate,
            yCoordinate
          );
          positionOfDobindaValue = Public.MakeCalculus.returnPosition(
            endDobindaValue,
            invisibileRadiusX,
            invisibileRadiusY,
            xCoordinate,
            yCoordinate
          );
          positionOfImprumutValue = Public.MakeCalculus.returnPosition(
            endImprumutValue,
            invisibileRadiusX,
            invisibileRadiusY,
            xCoordinate,
            yCoordinate
          );

          arrOfElipsePosition = [
            positionOfComisionValue,
            positionOfDobindaValue,
            positionOfImprumutValue
          ];

          (function checkPosition(arrayWhthPositions) {
            for (var i = 0; i < arrayWhthPositions.length; i++) {
              if (arrayWhthPositions[i][0] < 300) {
                arrayWhthPositions[i][0] =
                  arrayWhthPositions[i][0] - 50;
              } else {
                arrayWhthPositions[i][0] =
                  arrayWhthPositions[i][0];
              }
            }
          })(arrOfElipsePosition);

          var comisionArc = new createjs.Shape().set({
            x: 300,
            y: 300
          });
          comisionArc.graphics.s("#f98e3d").ss(8); // or ss for strokeStyle
          comisionArc.graphics.arc(
            0,
            0,
            radius,
            startAngle,
            endComisionValue,
            false
          );
          gBaseCanvasStage.addChild(comisionArc);
          gBaseCanvasStage.update();

          var dobindaArc = new createjs.Shape().set({
            x: 300,
            y: 300
          });
          comisionArc.graphics.s("#00b7f4").ss(8); // or ss for strokeStyle
          comisionArc.graphics.arc(
            0,
            0,
            radius,
            endComisionValue,
            endDobindaValue,
            false
          );
          gBaseCanvasStage.addChild(dobindaArc);
          gBaseCanvasStage.update();

          var imprumutArc = new createjs.Shape().set({
            x: 300,
            y: 300
          });
          comisionArc.graphics.s("#0476bd").ss(8); // or ss for strokeStyle
          comisionArc.graphics.arc(
            0,
            0,
            radius,
            endDobindaValue,
            endImprumutValue,
            false
          );
          gBaseCanvasStage.addChild(imprumutArc);
          gBaseCanvasStage.update();

          var grayArc = new createjs.Shape().set({x: 300, y: 300});
          comisionArc.graphics.s("#cacaca").ss(8); // or ss for strokeStyle
          comisionArc.graphics.arc(
            0,
            0,
            radius,
            endImprumutValue,
            endAngle,
            false
          );
          gBaseCanvasStage.addChild(grayArc);
          gBaseCanvasStage.update();

          updateTxtValueAndPositionCanvas(
            comisionValue,
            dobindaValue,
            imprumutValue,
            rataLunaraValue,
            totalValue,
            arrOfElipsePosition
          );
        }

        function updateTxtValueAndPositionCanvas(
          comisionValue,
          dobindaValue,
          imprumutValue,
          rataLunaraValue,
          totalValue,
          myArray
        ) {
          var yPositionCoefficient = 18;
          Public.MakeCalculus.drawingModalCalc(
            comisionValue,
            dobindaValue,
            imprumutValue,
            maxValuSpeedometer,
            "business-modalCalculator"
          );

          if (windowWidth <= 991) {
            var txtTotalDeRambursat;
            if (language === 'ro') {
              txtTotalDeRambursat = new createjs.Text(
                "Total de rambursat",
                "14px Rubik",
                "#000"
              );
            } else {
              txtTotalDeRambursat = new createjs.Text(
                "Подлежит погашению",
                "12px Rubik",
                "#000"
              );
            }
            ;

            txtTotalDeRambursat.x = 230;
            txtTotalDeRambursat.y = 315;
            gBaseCanvasStage.addChild(txtTotalDeRambursat);
            gBaseCanvasStage.update();

            totalValue = Public.MakeCalculus.numberWithCommas(
              totalValue
            );
            var txtTotalDeRambursatValue = new createjs.Text(
              totalValue + currencyWithNoSpace,
              "24px Rubik",
              "#000"
            );
            txtTotalDeRambursatValue.x = currencyWithNoSpace !== 'MDL' ? 260 : 240;
            txtTotalDeRambursatValue.y = 275;
            gBaseCanvasStage.addChild(txtTotalDeRambursatValue);
            gBaseCanvasStage.update();
            var txtRataLunara;
            if (language === 'ro') {
              txtRataLunara = new createjs.Text(
                "Rata lunară ",
                "14px sans-serif",
                "#000"
              );
            } else {
              txtRataLunara = new createjs.Text(
                "Еж. оплата ",
                "14px sans-serif",
                "#000"
              );
            }
            ;

            txtRataLunara.x = 252;
            txtRataLunara.y = 420;
            gBaseCanvasStage.addChild(txtRataLunara);
            gBaseCanvasStage.update();

            rataLunaraValue = Public.MakeCalculus.numberWithCommas(
              rataLunaraValue
            );
            var txtRataLunaraValue = new createjs.Text(
              rataLunaraValue + currencyWithNoSpace,
              "500 24px Rubik",
              "#000"
            );
            txtRataLunaraValue.x = currencyWithNoSpace !== 'MDL' ? 270 : 250;
            txtRataLunaraValue.y = 388;
            gBaseCanvasStage.addChild(txtRataLunaraValue);
            gBaseCanvasStage.update();
          } else if (windowWidth > 992) {
            var txtComisionTitle;
            if (language === 'ro') {
              txtComisionTitle = new createjs.Text(
                "Comision",
                "14px Rubik",
                "#f98e3d"
              );
            } else {
              txtComisionTitle = new createjs.Text(
                "Комиссия",
                "14px Rubik",
                "#f98e3d"
              );
            }
            ;

            txtComisionTitle.x = myArray[0][0];
            txtComisionTitle.y = myArray[0][1];
            gBaseCanvasStage.addChild(txtComisionTitle);
            gBaseCanvasStage.update();

            var txtComisionValue = new createjs.Text(
              comisionValue + currencyWithNoSpace,
              "18px Rubik",
              "#f98e3d"
            );
            txtComisionValue.x = myArray[0][0];
            txtComisionValue.y =
              myArray[0][1] - yPositionCoefficient;
            gBaseCanvasStage.addChild(txtComisionValue);
            gBaseCanvasStage.update();
            var txtDobindaTitle;
            if (language === 'ro') {
              txtDobindaTitle = new createjs.Text(
                "Dobândă",
                "14px Rubik",
                "#00b7f4"
              );
            } else {
              txtDobindaTitle = new createjs.Text(
                "Проценты",
                "14px Rubik",
                "#00b7f4"
              );
            }
            ;

            txtDobindaTitle.x = myArray[1][0];
            txtDobindaTitle.y = myArray[1][1];
            gBaseCanvasStage.addChild(txtDobindaTitle);
            gBaseCanvasStage.update();

            dobindaValue = Public.MakeCalculus.numberWithCommas(
              dobindaValue
            );

            var txtDobindaValue = new createjs.Text(
              dobindaValue + currencyWithNoSpace,
              "18px Rubik",
              "#00b7f4"
            );
            txtDobindaValue.x = myArray[1][0];
            txtDobindaValue.y =
              myArray[1][1] - yPositionCoefficient;
            gBaseCanvasStage.addChild(txtDobindaValue);
            gBaseCanvasStage.update();
            var txtImprumutTitle;
            if (language === 'ro') {
              txtImprumutTitle = new createjs.Text(
                "Credit",
                "14px Rubik",
                "#0476bd"
              );
            } else {
              txtImprumutTitle = new createjs.Text(
                "Кредит",
                "14px Rubik",
                "#0476bd"
              );
            }
            ;

            txtImprumutTitle.x = myArray[2][0];
            txtImprumutTitle.y = myArray[2][1];
            gBaseCanvasStage.addChild(txtImprumutTitle);
            gBaseCanvasStage.update();

            imprumutValue = Public.MakeCalculus.numberWithCommas(
              imprumutValue
            );

            var txtImprumutValue = new createjs.Text(
              imprumutValue + currencyWithNoSpace,
              "18px Rubik",
              "#0476bd"
            );
            txtImprumutValue.x = myArray[2][0];
            txtImprumutValue.y =
              myArray[2][1] - yPositionCoefficient;
            gBaseCanvasStage.addChild(txtImprumutValue);
            gBaseCanvasStage.update();
            var txtTotalDeRambursat;
            if (language === 'ro') {
              txtTotalDeRambursat = new createjs.Text(
                "Total de rambursat",
                "14px Rubik",
                "#000"
              );
            } else {
              txtTotalDeRambursat = new createjs.Text(
                "Подлежит погашению",
                "12px Rubik",
                "#000"
              );
            }
            ;

            txtTotalDeRambursat.x = 245;
            txtTotalDeRambursat.y = 312;
            gBaseCanvasStage.addChild(txtTotalDeRambursat);
            gBaseCanvasStage.update();

            totalValue = Public.MakeCalculus.numberWithCommas(
              totalValue
            );

            var txtTotalDeRambursatValue = new createjs.Text(
              totalValue + currencyWithNoSpace,
              "24px Rubik",
              "#000"
            );
            txtTotalDeRambursatValue.x = currencyWithNoSpace !== 'MDL' ? 270 : 250;
            txtTotalDeRambursatValue.y = 285;
            gBaseCanvasStage.addChild(txtTotalDeRambursatValue);
            gBaseCanvasStage.update();
            var txtRataLunara;
            if (language === 'ro') {
              txtRataLunara = new createjs.Text(
                "Rata lunară ",
                "14px Rubik",
                "#000"
              );
            } else {
              txtRataLunara = new createjs.Text(
                "Еж. оплата ",
                "14px Rubik",
                "#000"
              );
            }
            ;

            txtRataLunara.x = 265;
            txtRataLunara.y = 410;
            gBaseCanvasStage.addChild(txtRataLunara);
            gBaseCanvasStage.update();

            rataLunaraValue = Public.MakeCalculus.numberWithCommas(
              rataLunaraValue
            );

            var txtRataLunaraValue = new createjs.Text(
              rataLunaraValue + currencyWithNoSpace,
              "500 20px Rubik",
              "#000"
            );
            txtRataLunaraValue.x = currencyWithNoSpace !== 'MDL' ? 275 : 260;
            txtRataLunaraValue.y = 384;
            gBaseCanvasStage.addChild(txtRataLunaraValue);
            gBaseCanvasStage.update();
          }
        }

        drawArcs(
          comisionValue.toFixed(0),
          dobindaValue.toFixed(0),
          imprumutValue,
          rataLunaraValue.toFixed(0),
          totalValue.toFixed(0)
        );
      },
      updateFooterValues(
        dae,
        monthlyCommission,
        grantingCommission,
        fixedRate,
        totalLoanCost,
        monthlyRate
      ) {
        $footerGrantComm = $("#footer-comision-de-acordare");
        $footerMonthlyComm = $("#footer-comision-lunar");
        $footerDae = $("#footer-dae");
        $rataDobinziiFixa = $("#footer-rata-dobinda-fixa");
        $totalLoanCost = $("#total-loan-cost");

        $footerGrantComm.html(grantingCommission.toFixed(2));
        if (MYDATA.details.comision_de_acordare_luni) {
          $(".analyse-commission-text").show();
          $("#granting-months").html(MYDATA.init_data.comision_de_acordare_luni);
        } else {
          $(".analyse-commission-text").hide();
        }
        $footerMonthlyComm.html(monthlyCommission);
        $footerDae.html(dae);
        $rataDobinziiFixa.html(fixedRate);
        $totalLoanCost.html(totalLoanCost.toFixed(2));
        $("#footer-monthly-rate").html(monthlyRate.toFixed(2));
      },

      carWidthFunction: function () {
        var carWidth;
        var windowWidth = window.innerWidth;

        if (windowWidth < 767) {
          carWidth = $(
            ".hidden-car-slider-on-mobile .animated-car.on-mobile "
          ).outerWidth();
        } else {
          carWidth = $(
            ".car-slider .content .animated-car "
          ).outerWidth();
        }

        return carWidth;
      },

      elementWidthFunction: function () {
        var slideWidth;
        var windowWidth = window.innerWidth;

        if (windowWidth < 767) {
          slideWidth = $(
            ".hidden-car-slider-on-mobile .hidden-mobile-slider  "
          ).outerWidth();
        } else if (windowWidth > 768) {
          slideWidth = $(
            " .obtain-loan-slider .car-slider .content .step"
          ).outerWidth();
        }

        return slideWidth;
      },

      addItems: function (numberOfItemToRender) {
        var clonedSlideItems = [];
        Public.animationArray = [];
        Slider.animations = [];
        var $hiddenMobileSlider = $(
          ".obtain-loan-slider .hidden-car-slider-on-mobile .hidden-mobile-slider "
        );
        var $fullSliderArea = $(
          ".obtain-loan-slider .full-slider-area"
        );
        var $parentDiv = $(".obtain-loan-slider .car-slider .content");

        $(".animated-car .car-with-no_wheel").css({
          transform: "scale(1.34)"
        });
        $(".animated-car.on-mobile .car-with-no_wheel").css({
          transform: "scale(1.1)"
        });
        var elementWidthPercent = 100 / numberOfItemToRender;
        if (Public.pageType === 'simple-auto') {
          elementWidthPercent = 100 / Public.carSliderItemsConfig1.length;
        }
        var elementWidthPixel, divItem, mobileSlideItem, carWidth;
        var gajSliderContent = document.getElementById(
          "full-slider-gaj"
        );
        var faraGajSliderContent = document.getElementById(
          "full-slider-faragaj"
        );
        var businessSliderContent = document.getElementById(
          "full-slider-auto"
        );

        if (numberOfItemToRender === 3) {
          clonedSlideItems = Public.carSliderItemsConfig1;
          $(".obtain-loan-slider")
            .find(".main-full-slider-area")
            .empty();
          clonedHTML = faraGajSliderContent.innerHTML;
          $(".main-full-slider-area").append(clonedHTML);
        } else if (numberOfItemToRender === 4) {
          clonedSlideItems = Public.carSliderItemsConfig2;
          $(".obtain-loan-slider")
            .find(".main-full-slider-area")
            .empty();
          clonedHTML = gajSliderContent.innerHTML;
          $(".main-full-slider-area").append(clonedHTML);
        } else if (numberOfItemToRender === 5) {
          clonedSlideItems = Object.assign(
            [],
            Public.carSliderItemsConfig1
          );
          $(".obtain-loan-slider")
            .find(".main-full-slider-area")
            .empty();
          if (businessSliderContent) {
            clonedHTML = businessSliderContent.innerHTML;
            $(".main-full-slider-area").append(clonedHTML);
          }
        }

        carWidth = Public.MakeCalculus.carWidthFunction();

        if (Public.pageType === 'simple-auto') {
          numberOfItemToRender = Public.carSliderItemsConfig1.length;
        }

        for (var i = 0; i < numberOfItemToRender; i++) {
          if (i === 0) {
            divItem = document.innerHTML =
              '<div class="step" data-slide="' + i + '" data-bg-inactive="' +
              clonedSlideItems[i].item_img_inactive +
              '"  data-bg-active="' +
              clonedSlideItems[i].item_img_active +
              '" style="background-repeat: no-repeat; width: ' +
              elementWidthPercent +
              "%; background-image: url('" +
              clonedSlideItems[i].item_img_active +
              '\'); background-position: center; background-size: contain; position: relative; height:100px"><div style="font-weight: 600;" class="titleForStep">' +
              clonedSlideItems[i].item_title +
              "</div></div>";
          } else {
            divItem = document.innerHTML =
              '<div class="step" data-slide="' + i + '" data-bg-inactive="' +
              clonedSlideItems[i].item_img_inactive +
              '"  data-bg-active="' +
              clonedSlideItems[i].item_img_active +
              '" style="background-repeat: no-repeat; width: ' +
              elementWidthPercent +
              "%; background-image: url('" +
              clonedSlideItems[i].item_img_inactive +
              '\'); background-position: center; background-size: contain; position: relative; height:100px"><div class="titleForStep">' +
              clonedSlideItems[i].item_title +
              "</div></div>";
          }
          mobileSlideItem = document.innerHTML =
            '<div class="step" style="background-image: url(\'' +
            clonedSlideItems[i].item_img_active +
            '"><div class="txt-content"> ' +
            clonedSlideItems[i].item_title +
            " </div></div>";
          $parentDiv.append(divItem);
          $hiddenMobileSlider.append(mobileSlideItem);
        }

        elementWidthPixel = Public.MakeCalculus.elementWidthFunction();

        firstCarPosition = currentCarPosition =
          elementWidthPixel / 2 - carWidth / 2;

        $(".animated-car ").css({
          transform: "translateX(" + firstCarPosition + "px)"
        });
        $(".animated-car.on-mobile ").css({
          transform: "translateX(" + 21 + "px)"
        });
      },
      buildAnimation: function (currentCarPosition, nextCarPosition) {
        carAnimation = anime.timeline({
          direction: "normal",
          loop: false,
          autoplay: false
        });

        carAnimation
          .add({
            targets: ".back-wheel",
            rotate: {value: 360},
            duration: "500"
          })
          .add({
            targets: ".nowheel-car",
            rotate: -4,
            duration: "600",
            offset: "-=400"
          })
          .add({
            targets: ".nowheel-car",
            rotate: 0,
            duration: "400"
          })
          .add({
            targets: ".animated-car",
            translateX: [
              currentCarPosition + "px",
              nextCarPosition + "px"
            ],
            duration: "1800",
            offset: "-=800",
            easing: "easeInOutQuart"
          })
          .add({
            targets: ".nowheel-car",
            rotate: 4,
            duration: "500",
            offset: "-=1000"
          })
          .add({
            targets: ".nowheel-car",
            rotate: 0,
            duration: "500",
            offset: "-=200"
          });
        return carAnimation;
      },
      animateMovingCar: function (numberOfItems, elementWidthPixel) {
        animationArray = Public.animationArray;
        var carWidth, currentCarPosition, firstCarPosition;
        var $car = $(".obtain-loan-slider .car-slider .animated-car");

        carWidth = Public.MakeCalculus.carWidthFunction();
        windowWidth = window.innerWidth;

        currentCarPosition = elementWidthPixel / 2 - carWidth / 2;

        firstCarPosition = currentCarPosition;

        for (i = 1; i < numberOfItems; i++) {
          if (windowWidth < 999) {
            currentCarPosition = 21;
            nextCarPosition = 21;
          } else {
            nextCarPosition =
              elementWidthPixel / 2 -
              carWidth / 2 +
              elementWidthPixel * i;
          }

          animationArray.push(
            Public.MakeCalculus.buildAnimation(
              currentCarPosition,
              nextCarPosition
            )
          );
          currentCarPosition = nextCarPosition;
        }

        $car.css({
          "-webkit-transform":
            "translateX(" + firstCarPosition + "px" + ")",
          "-moz-transform":
            "translateX(" + firstCarPosition + "px" + ")",
          "-ms-transform":
            "translateX(" + firstCarPosition + "px" + ")",
          "-o-transform":
            "translateX(" + firstCarPosition + "px" + ")",
          transform: "translateX(" + firstCarPosition + "px" + ")"
        });

        return animationArray;
      },
      destroySlickEmptySliderContent: function () {
        var $mobileSlider = $(
          ".obtain-loan-slider .hidden-car-slider-on-mobile .hidden-mobile-slider"
        );
        $(".obtain-loan-slider .car-slider .content .step").remove();
        $mobileSlider.slick("unslick");
        $(".obtain-loan-slider .main-full-slider-area").slick(
          "unslick"
        );

        $mobileSlider.empty();
      },
      checkUserInput: function () {
        const monthlyCommission = this.calculateMonthlyCommission(imprumutValue)
        if (Public.fileConfigType === "cu gaj") {
          Public.MakeCalculus.redrawSpeedometerValues(
            true,
            imprumutValue,
            months,
            monthlyCommission,
            MYDATA.details.comision_de_acordare_min_value,
            MYDATA.details.comision_de_acrodare_percent,
            MYDATA.details.rata_dobinzii[months],
            MYDATA.details.comision_lunar_max ? MYDATA.details.comision_lunar_max : MYDATA.details.comision_lunar,
            Public.MakeCalculus.calculateMaxValueOfCalculator()
          );
        } else if (Public.fileConfigType === "fara gaj") {
          Public.MakeCalculus.redrawSpeedometerValues(
            false,
            imprumutValue,
            months,
            monthlyCommission,
            MYDATA.details.comision_de_acordare_min_value,
            MYDATA.details.comision_de_acrodare_percent,
            MYDATA.details.rata_dobinzii[months],
            MYDATA.details.comision_lunar_max ? MYDATA.details.comision_lunar_max : MYDATA.details.comision_lunar,
            Public.MakeCalculus.calculateMaxValueOfCalculator()
          );
        }
      },

      redrawSpeedometerValues: function (
        typeOfMounthlyComisionVal,
        moneyLoan,
        totalMonths,
        monthlyCommission,
        grantingCommissionMinValue,
        grantingCommissionPercent,
        interestRate,
        monthlyCommissionMaxVal,
        maxValuSpeedometer
      ) {
        var totalMonthsPrev = ((totalMonths > 5) ? (totalMonths - 5) : (totalMonths + 10));
        var totalMonthsNext = ((totalMonths < 56) ? (totalMonths + 5) : 50);

        var grantComm = Public.MakeCalculus.calculateGrantingCommission(
          moneyLoan,
          grantingCommissionPercent,
          grantingCommissionMinValue,
          MYDATA.details
        );
        let rates = calculateRatesList (MYDATA.details, totalMonths, grantComm, interestRate, monthlyCommission, moneyLoan);
        let ratesPrev = calculateRatesList (MYDATA.details, totalMonthsPrev, grantComm, MYDATA.details.rata_dobinzii[totalMonthsPrev], monthlyCommission, moneyLoan);
        let ratesNext = calculateRatesList (MYDATA.details, totalMonthsNext, grantComm, MYDATA.details.rata_dobinzii[totalMonthsNext], monthlyCommission, moneyLoan);

        let totalLoan = 0;
        let totalInterest = 0;
        let totalPaymentCommission = 0;
        let paymentWithoutInitialCommission = 0;
        for (let i =1; i < rates.length; i++) {
          totalLoan += parseFloat(rates[i].loan);
          totalInterest += parseFloat(rates[i].interest);
          totalPaymentCommission += parseFloat(rates[i].commission);
          paymentWithoutInitialCommission += parseFloat(rates[i].monthRate);
        }
        var totalCommission = totalPaymentCommission

        var monthlyRate = MYDATA.details.comision_de_acordare_luni ?  parseFloat(rates[MYDATA.details.comision_de_acordare_luni + 1].monthRate) : parseFloat(rates[1].monthRate);
        var monthlyRatePrev = MYDATA.details.comision_de_acordare_luni ?  parseFloat(ratesPrev[MYDATA.details.comision_de_acordare_luni + 1].monthRate) : parseFloat(ratesPrev[1].monthRate);
        var monthlyRateNext = MYDATA.details.comision_de_acordare_luni ?  parseFloat(ratesNext[MYDATA.details.comision_de_acordare_luni + 1].monthRate) : parseFloat(ratesNext[1].monthRate);

        var totalAmount = 0;
        var totalAmountPrev = 0;
        var totalAmountNext = 0;

        for (let i = 1; i < rates.length; i++) {
          totalAmount += parseFloat(rates[i].monthRate);
        }
        for (let i = 1; i < ratesPrev.length; i++) {
          totalAmountPrev += parseFloat(ratesPrev[i].monthRate);
        }
        for (let i = 1; i < ratesNext.length; i++) {
          totalAmountNext += parseFloat(ratesNext[i].monthRate);
        }
        var totalInterestRate = totalInterest

        var monhllyComm = MYDATA.details.comision_de_acordare_luni ?  parseFloat(rates[MYDATA.details.comision_de_acordare_luni + 1].commission) : parseFloat(rates[1].commission);
        monhllyComm = monhllyComm.toFixed(2);

        var myArr = [];
        var myArrPrev = [];
        var myArrNext = [];
        var daeCascoArr = [];
        var daeCASCO = 0.045;
        if (MYDATA.init_data.cascoPercent) {
          daeCASCO = MYDATA.init_data.cascoPercent;
        }

        for (let i = 0; i < totalMonths + 1; i++) {
          if (i === 0) {
            myArr.push(-(moneyLoan));
            daeCascoArr.push(
              -moneyLoan * daeCASCO
            );
          } else {
            myArr.push(rates[i].monthRate);
            daeCascoArr.push(rates[i].monthRate);
          }
        }

        for (let i = 0; i < totalMonthsPrev + 1; i++) {
          if (i == 0) {
            myArrPrev.push(-(moneyLoan));
          } else {
            myArrPrev.push(ratesPrev[i].monthRate);
          }
        }

        for (let i = 0; i < totalMonthsNext + 1; i++) {
          if (i == 0) {
            myArrNext.push(-(moneyLoan));
          } else {
            myArrNext.push(ratesNext[i].monthRate);
          }
        }

        var dae = Public.MakeCalculus.calcDAE(myArr);
        var daePrev = Public.MakeCalculus.calcDAE(myArrPrev);
        var daeNext = Public.MakeCalculus.calcDAE(myArrNext);
        var daeCASCOVal = Public.MakeCalculus.calcDAE(daeCascoArr);
        dae = dae.toFixed(2);
        daeCASCOVal = daeCASCOVal.toFixed(2);
        dae = Number(dae);
        daeCASCOVal = Number(daeCASCOVal);
        Public.MakeCalculus.updateFooterValues(
          dae,
          monhllyComm,
          grantComm,
          (MYDATA.details.rata_dobinzii[totalMonths] * 100).toFixed(2),
          totalAmount - moneyLoan,
          monthlyRate
        );

        var date = new Date();

        var monthNames = [];
        if (language === 'ro') {
          monthNames = [
            "Ianuarie",
            "Februarie",
            "Martie",
            "Aprilie",
            "Mai",
            "Iunie",
            "Iulie",
            "August",
            "Septembrie",
            "Octombrie",
            "Noiembrie",
            "Decembrie"
          ];
        } else {
          monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
        }
// calculate amount paid by sponsor in dependence of currency for auto
        // calculator (eur&&usd)
        var amountPaidBySponsor;
        if (currencyWithNoSpace === "$") {
          amountPaidBySponsor =
            moneyLoan * Public.currenciesBussNeeds.usd.releaseRate -
            grantComm * Public.currenciesBussNeeds.usd.releaseRate;
        } else if (currencyWithNoSpace === "€") {
          amountPaidBySponsor =
            moneyLoan * Public.currenciesBussNeeds.eur.releaseRate -
            grantComm * Public.currenciesBussNeeds.eur.releaseRate;
        } else {
          amountPaidBySponsor =
            moneyLoan -
            grantComm;
        }

        var withdrawalFee =
          (amountPaidBySponsor * MYDATA.init_data.cashWithdrawalFee) /
          100;
        var amountPaidByClientMD = amountPaidBySponsor - withdrawalFee;
        if (MYDATA.details.comision_de_acordare_luni) {
          amountPaidByClientMD -= grantComm;
        }

        var pdfGenerateData = {
          grantingCommissionPayInMonths: MYDATA.details.comision_de_acordare_luni ? MYDATA.details.comision_de_acordare_luni : null,
          lendingTimeMonths: totalMonths,
          borrowAmount: moneyLoan.toFixed(2),
          internActiveCurrency: getCurrencyLetterValue(),
          grantingCommissionPercent: (
            grantingCommissionPercent * 100
          ).toFixed(2),
          grantingCommissionMoney: grantComm.toFixed(2),
          minGrantingCommission:
          MYDATA.details.comision_de_acordare_min_value,
          fixedAnnualInterestRate: (
            MYDATA.details.rata_dobinzii[totalMonths] * 100
          ).toFixed(2),
          monthlyServiceFee: monhllyComm,
          monthlyServiceType: getCurrencyLetterValue(),
          insuranceCASCO: daeCASCO * 100,
          daeWithCasco: daeCASCOVal,
          daePercent: dae,
          cashWithdrawalFeePercent:
          MYDATA.init_data.cashWithdrawalFee,
          cashWithdrawalFeeMoney: withdrawalFee.toFixed(2),
          amountPaidBySponsor: amountPaidBySponsor.toFixed(2),
          amountPaidByClientMD: amountPaidByClientMD.toFixed(2),
          montlyRate: monthlyRate.toFixed(2),
          monthlyRateGranting: rates ? rates[1].monthRate : null,
          finalTotalAmountPaidByCustomer: totalAmount.toFixed(2),
          borrowYear: date.getFullYear(),
          borrowDate: date.getDate(),
          borrowMonthName: monthNames[date.getMonth()],
          loanRates: rates,
          totalLoan: totalLoan.toFixed(2),
          totalInterest: totalInterest.toFixed(2),
          totalCommission: totalPaymentCommission.toFixed(2),
          paymentWithoutInitialCommission: MYDATA.details.comision_de_acordare_luni ? totalAmount.toFixed(2) : (totalAmount - grantComm).toFixed(2),
          isClientPrecontract: true,
          representativeExamples: [
            {
              montlyRate: monthlyRate.toFixed(2),
              lendingTimeMonths: totalMonths,
              finalTotalAmountPaidByCustomer: totalAmount.toFixed(2),
              fixedAnnualInterestRate: (
                MYDATA.details.rata_dobinzii[totalMonths] * 100
              ).toFixed(2),
              daePercent: dae.toFixed(2)
            },
            {
              montlyRate: monthlyRatePrev.toFixed(2),
              lendingTimeMonths: totalMonthsPrev,
              finalTotalAmountPaidByCustomer: totalAmountPrev.toFixed(2),
              fixedAnnualInterestRate: (
                MYDATA.details.rata_dobinzii[totalMonthsPrev] * 100
              ).toFixed(2),
              daePercent: daePrev.toFixed(2)
            },
            {
              montlyRate: monthlyRateNext.toFixed(2),
              lendingTimeMonths: totalMonthsNext,
              finalTotalAmountPaidByCustomer: totalAmountNext.toFixed(2),
              fixedAnnualInterestRate: (
                MYDATA.details.rata_dobinzii[totalMonthsNext] * 100
              ).toFixed(2),
              daePercent: daeNext.toFixed(2)
            }
          ]
        };

        pdfGenerateData.representativeExamples = pdfGenerateData.representativeExamples.sort((a, b) => (a.lendingTimeMonths > b.lendingTimeMonths) ? 1 : -1);
        if (pdfGenerateData.internActiveCurrency === "USD") {
          pdfGenerateData.usdReleaseRate = Number(Public.currenciesBussNeeds.usd.releaseRate).toFixed(2);
          // changed to release rate
          // pdfGenerateData.usdPaymentRate = Number(Public.currenciesBussNeeds.usd.paymentRate).toFixed(2);
          pdfGenerateData.usdPaymentRate = Number(Public.currenciesBussNeeds.usd.releaseRate).toFixed(2);
        }
        if (pdfGenerateData.internActiveCurrency === "EUR") {
          pdfGenerateData.usdReleaseRate = Number(Public.currenciesBussNeeds.eur.releaseRate).toFixed(2);
          // changed to release rate
          // pdfGenerateData.usdPaymentRate = Number(Public.currenciesBussNeeds.eur.paymentRate).toFixed(2);
          pdfGenerateData.usdPaymentRate = Number(Public.currenciesBussNeeds.eur.releaseRate).toFixed(2);
        }
        var cascoLimit = 8000;
        if (MYDATA.init_data.cascoLimit) {
          cascoLimit = MYDATA.init_data.cascoLimit;
        }
        if (moneyLoan > cascoLimit) {
          pdfGenerateData.isCasco = true;
        }

        var pdf = Public.MakeCalculus.generateLinkPDF(pdfGenerateData);
        var paymentPdf = Public.MakeCalculus.generatePaymentLinkPDF(pdfGenerateData);
        Public.PrecontratDataBusinessNeeds = pdfGenerateData;

        if (Public.calculatorType === "business-calculator") {
          Public.MakeCalculus.initSpeedometerCanvasBusiness(
            totalCommission,
            totalInterestRate,
            moneyLoan,
            monthlyRate,
            totalAmount,
            maxValuSpeedometer
          );
          $(".pdfPrecontract").attr("href", pdf);
          $(".pdfPayment").attr("href", paymentPdf);

          $(window).resize(function () {
            Public.MakeCalculus.initSpeedometerCanvasBusiness(
              totalCommission,
              totalInterestRate,
              moneyLoan,
              monthlyRate,
              totalAmount,
              maxValuSpeedometer
            );
            $(".pdfPrecontract").attr("href", pdf);
            $(".pdfPayment").attr("href", paymentPdf);
          });
        } else if (Public.calculatorType === "necesitati-calculator") {
          Public.MakeCalculus.initSpeedometerCanvasNecesitati(
            totalCommission,
            totalInterestRate,
            moneyLoan,
            monthlyRate,
            totalAmount
          );
          $(".pdfPrecontract").attr("href", pdf);
          $(".pdfPayment").attr("href", paymentPdf);
          $(window).resize(function () {
            Public.MakeCalculus.initSpeedometerCanvasNecesitati(
              totalCommission,
              totalInterestRate,
              moneyLoan,
              monthlyRate,
              totalAmount
            );
            $(".pdfPrecontract").attr("href", pdf);
            $(".pdfPayment").attr("href", paymentPdf);
          });
        }
      },

      IRR(values, guess) {
        if (guess === undefined) {
          guess = 0.01;
        }

        var maxIterationCount = 20;
        var absoluteAccuracy = 0.0000001;

        var x0 = guess;
        var x1;

        var i = 0;
        while (i < maxIterationCount) {
          // the value of the function (NPV) and its derivate can be calculated in the same loop
          var fValue = 0;
          var fDerivative = 0;
          for (var k = 0; k < values.length; k++) {
            fValue += values[k] / Math.pow(1 + x0, k);
            fDerivative +=
              (-k * values[k]) / Math.pow(1 + x0, k + 1);
          }

          // the essense of the Newton-Raphson Method
          x1 = x0 - fValue / fDerivative;

          if (Math.abs(x1 - x0) <= absoluteAccuracy) {
            return x1;
          }

          x0 = x1;
          ++i;
        }
        // maximum number of iterations is exceeded
        return "NaN";
      },

      calcDAE(irrArray) {
        dae = Public.MakeCalculus.IRR(irrArray) * 1200;
        dae = (Math.pow(1 + dae / 100 / 12, 12) - 1) * 100;

        return dae;
      },

      calculateMaxValueOfCalculator: function () {
        MYDATA = Public.MakeCalculus.checkWhichFile();
        var monthlyCommission,
          totalMonths,
          interestRate,
          moneyLoan,
          grantingCommissionPercent,
          grantingCommissionMinValue,
          monthlyCommissionMaxVal,
          monthlyRate;

        totalMonths = MYDATA.init_data.termen_imprumut_max;
        interestRate = MYDATA.details.rata_dobinzii[totalMonths];
        moneyLoan = MYDATA.init_data.suma_imprumut_max;
        grantingCommissionPercent =
          MYDATA.details.comision_de_acrodare_percent;
        grantingCommissionMinValue =
          MYDATA.details.comision_de_acordare_min_value;
        monthlyCommission = this.calculateMonthlyCommission(moneyLoan);
        if (MYDATA.init_data.comision_lunar_type && MYDATA.init_data.comision_lunar_type.values === 'absolute') {
          // cu gaj
          monthlyCommissionMaxVal = MYDATA.details.comision_lunar;
        } else {
          //fara gaj
          monthlyCommissionMaxVal =
            MYDATA.details.comision_lunar * moneyLoan;

          if (monthlyCommissionMaxVal >  MYDATA.details.comision_lunar_max) {
            monthlyCommissionMaxVal = MYDATA.details.comision_lunar_max;
          }
        }

        monthlyRate = this.calculateMonthlyRate(
          totalMonths,
          interestRate,
          moneyLoan,
          monthlyCommission,
          monthlyCommissionMaxVal
        );
        var totalAmount = this.calculateTotalAmount(
          monthlyRate,
          totalMonths,
          grantingCommissionPercent,
          grantingCommissionMinValue,
          moneyLoan
        );


        return totalAmount + 2000;
      },

      drawingModalCalc: function (
        commission,
        interestRate,
        moneyLoan,
        maxVal,
        modalID
      ) {
        $sel1 = $(
          "#" +
          modalID +
          " .modal-body .values .borrow-value .width-percent .inner-width-content"
        );
        $sel2 = $(
          "#" +
          modalID +
          " .modal-body .values .interest-value .width-percent .inner-width-content"
        );
        $sel3 = $(
          "#" +
          modalID +
          " .modal-body .values .commission-value .width-percent .inner-width-content"
        );

        var Comm, Loan, Rate;
        var maxWidth = maxVal;

        Comm = (commission * 100) / maxWidth;
        Comm = Number(Comm);
        Loan = (moneyLoan * 100) / maxWidth;
        Loan = Number(Loan);
        Rate = (interestRate * 100) / maxWidth;
        Rate = Number(Rate);

        $sel1.css({
          width: Loan
        });

        $sel2.css({
          width: Rate
        });

        $sel3.css({
          width: Comm
        });
      },

      generateLinkPDF: function (obj) {
        return "/api/generate/pdf?" + $.param(obj);
      },
      generatePaymentLinkPDF: function (obj) {
        return "/api/generate/payment/pdf?" + $.param(obj);
      },

      allowOnlyNumbers: function (evt) {
        var charCode = evt.which ? evt.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
          return false;

        return true;
      },

      validateSendOfferInput() {
        var validateEmail = function (elementValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]/;
          return emailPattern.test(elementValue);
        };

        var $input = $("#emailModalOfferr").find("input");
        var $subbmitBtn = $("#emailModalOfferr").find("form button");
        var $error = $("#emailModalOfferr").find(
          ".error-invalid-email"
        );

        $input.on("change keypress keyup", function () {
          var valid = validateEmail($input.val());

          if (!valid) {
            $input.css({
              "border-bottom-color": "#e73b38",
              color: "#e73b38"
            });
            $subbmitBtn.attr("disabled", true);
            $error.css("opacity", "1");
          } else {
            $subbmitBtn.attr("disabled", false);
            $error.css("opacity", "0");
            $input.css({
              "border-bottom-color": "#f2f2f2",
              color: "#0D6CB5"
            });
          }
        });
      }
    },

    InitAutoCalculator: function () {
      window.onload = () => {
        selectLogic();
      }
      Public.MakeCalculus.validateSendOfferInput();
      Public.MakeCalculus.emailPrecontractOffer();

      Public.fileConfigType = "auto";
      MYDATA = Public.MakeCalculus.checkWhichFile();
      minGrantCommission = MYDATA.init_data.comision_de_acordare_min;
      months = MYDATA.init_data.termenul_imprumutului;
      advantage_Money = MYDATA.init_data.advantageofMoney;
      advantage_Percent = MYDATA.init_data.advantageofPercent;
      car_Value = MYDATA.init_data.car_cost;
      var cashWithdrawalFeePercent =
        MYDATA.init_data.cashWithdrawalFeePercent;
      $("#footer-termen-imprumut").html(months);


      var adavatangePercentMax09;

      var $imprumutMinVal = $(
        ".auto-calculator .calculation-area .calculator .the-car-cost .min-amount .amount h5"
      );
      var $imprumutMaxVal = $(
        ".auto-calculator .calculation-area .calculator .the-car-cost .max-amount .amount h5"
      );
      var $imprumutRangeInput = $(" #output-rangevalue_car-price ");

      var $imprumutBoxInput = $("#rangevalue_car-price");

      var $landingTimeSlider = $("#changeRangeAdvantage");
      var $landingTimeMinVal = $(
        ".auto-calculator .calculation-area .calculator .have-advance-of .min-amount .amount h5"
      );
      var $landingTimeMaxVal = $(
        ".auto-calculator .calculation-area .calculator .have-advance-of .max-amount .amount h5"
      );
      var $landingTimePercentBoxInput = $(
        "#rangevalue_advantage-percent"
      );
      var $landingTimeMoneyBoxInput = $("#rangevalue_advantage-money");

      var $termenImprumutMinVal = $(
        ".auto-calculator .calculation-area .calculator .deadline-of-lending .min-amount .amount h5"
      );
      var $termenImprumutMaxVal = $(
        ".auto-calculator .calculation-area .calculator .deadline-of-lending .max-amount .amount h5"
      );
      var $termenImprumutRangeInput = $("#input_rangevalue_landing-time");
      var $termenImprumutBoxInput = $("#output_rangevalue_landing-time");
      var speedometerMaxVal = calcMaxAmount();


      loadChart();
      initDataInputs();
      Public.MakeCalculus.addItems(5);

      Public.MakeCalculus.initHiddenCalculator();

      function initDataInputs() {
        $imprumutMinVal.html(MYDATA.init_data.minValCarPrice + currencyWithSpace);
        $imprumutMaxVal.html(MYDATA.init_data.maxValCarPrice + currencyWithSpace);
        $imprumutRangeInput.attr({
          min: MYDATA.init_data.minValCarPrice,
          max: MYDATA.init_data.maxValCarPrice
        });
        $imprumutRangeInput.val(MYDATA.init_data.car_cost);
        $imprumutBoxInput.val(MYDATA.init_data.car_cost);
        $landingTimeSlider.val(MYDATA.init_data.advantageofPercent);
        $landingTimeMinVal.html(MYDATA.init_data.minAdvantage + "%");
        $landingTimeMaxVal.html(MYDATA.init_data.maxAdvantage + "%");
        $landingTimePercentBoxInput.val(
          MYDATA.init_data.advantageofPercent
        );
        $landingTimeMoneyBoxInput.val(
          MYDATA.init_data.advantageofMoney
        );
        if (language === 'ro') {
          $termenImprumutMinVal.html(
            MYDATA.init_data.termen_imprumut_min + " luni"
          );
          $termenImprumutMaxVal.html(
            MYDATA.init_data.termen_imprumut_max + " luni"
          );
        } else {
          $termenImprumutMinVal.html(
            MYDATA.init_data.termen_imprumut_min + " месяцев"
          );
          $termenImprumutMaxVal.html(
            MYDATA.init_data.termen_imprumut_max + " месяцев"
          );
        }
        ;


        $termenImprumutRangeInput.attr({
          min: MYDATA.init_data.termen_imprumut_min,
          max: MYDATA.init_data.termen_imprumut_max
        });

        $termenImprumutRangeInput.val(
          MYDATA.init_data.termenul_imprumutului
        );
        $termenImprumutBoxInput.val(
          MYDATA.init_data.termenul_imprumutului
        );
      }

      function isNumberKey(evt) {
        var charCode = evt.which ? evt.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
          return false;

        return true;
      }

      var $leftCar = $(".auto-calculator .left-car > .background-image ");
      var $rightCar = $(
        ".auto-calculator .right-car > .background-image "
      );
      $leftCar.on("click", function () {
        if (!$(this).hasClass("inactive") && !$(this).hasClass("disabled")) {
          var selector1 = $(this).addClass("inactive");
          for (var i = 0; i < 2; i++) {
            selector1
              .queue(function (next) {
                $(this)
                  .delay(300)
                  .addClass("blink-animation")
                  .queue(function (next) {
                    $(this).removeClass("blink-animation");
                    next();
                  });
                next();
              })
              .delay(300);
          }
          $(".auto-calculator .right-car > .background-image").queue(
            function (next) {
              $(this)
                .removeClass("active")
                .queue(function (next) {
                  $(this).removeClass("bentley-closed-door");
                  next();
                });
              next();
            }
          );
        }
        initDataDisplay();
      });
      $rightCar.on("click", function () {
        if (!$(this).hasClass("active") && !$(this).hasClass("disabled")) {
          $(this).queue(function (next) {
            $(this)
              .delay(300)
              .addClass("active")
              .queue(function (next) {
                $(this).addClass("bentley-closed-door");
                next();
              });
            next();
          });
          $(
            ".auto-calculator .left-car > .background-image "
          ).removeClass("inactive");
        }
        initDataDisplay();
      });

      if (Public.pageType === 'simple-auto') {
        if (MYDATA.uzat.disable) {
          $leftCar.addClass('disabled');
          $rightCar.click();
        }
        if (MYDATA.nou.disable) {
          $rightCar.addClass('disabled');
          $leftCar.click();
        }
      }

      $imprumutRangeInput.on("input", function () {
        car_Value = this.value;
        advantage_Money = (car_Value * advantage_Percent) / 100;
        $imprumutBoxInput.val(car_Value);
        $landingTimeMoneyBoxInput.val(
          Math.round((advantage_Percent * car_Value) / 100)
        );
        $landingTimePercentBoxInput.val(advantage_Percent);
        $("#footer-imprumut").html(car_Value - advantage_Money);
        adavatangePercentMax09 = advantage_Percent / 100;
        selectLogic();
      });

      $imprumutBoxInput.keypress(e => {
        selectLogic();
        return isNumberKey(e);
      });

      $imprumutBoxInput.on("input", function (event) {
        car_Value = this.value;

        if (!isNaN(car_Value)) {
          if (car_Value < MYDATA.init_data.minValCarPrice) {
            car_Value = MYDATA.init_data.minValCarPrice;
          } else if (car_Value > MYDATA.init_data.maxValCarPrice) {
            this.value = MYDATA.init_data.maxValCarPrice;
            car_Value = MYDATA.init_data.maxValCarPrice;
          }
        } else {
          car_Value = MYDATA.init_data.maxValCarPrice;
        }
        advantage_Money = (car_Value * advantage_Percent) / 100;
        $imprumutRangeInput.val(car_Value);
        $landingTimeMoneyBoxInput.val(advantage_Money);
        $("#footer-imprumut").html(car_Value - advantage_Money);
        adavatangePercentMax09 = advantage_Percent / 100;
        selectLogic();
      });

      $imprumutBoxInput.on("change", function () {
        car_Value = this.value;

        if (
          car_Value === "" ||
          car_Value < MYDATA.init_data.minValCarPrice ||
          isNaN(car_Value)
        ) {
          car_Value = MYDATA.init_data.minValCarPrice;
        }

        $(this).val(car_Value);
        selectLogic();
      });

      $landingTimeSlider.on("input", function () {
        advantage_Percent = Number(this.value);
        advantage_Money = (car_Value * advantage_Percent) / 100;
        $landingTimePercentBoxInput.val(advantage_Percent);
        $landingTimeMoneyBoxInput.val(advantage_Money);
        adavatangePercentMax09 = advantage_Percent / 100;
        $("#footer-imprumut").html(car_Value - advantage_Money);
        selectLogic();
      });

      $landingTimeMoneyBoxInput.on("input", debounce(function () {
        advantage_Money = this.value;
        if (advantage_Money === "" || isNaN(advantage_Money)) {
          advantage_Money =
            (car_Value * MYDATA.init_data.minAdvantage) / 100;
        }

        if (!isNaN(advantage_Money)) {
          if (
            advantage_Money <
            (car_Value * MYDATA.init_data.minAdvantage) / 100
          ) {
            advantage_Money =
              (car_Value * MYDATA.init_data.minAdvantage) / 100;
          } else if (
            advantage_Money >
            (car_Value * MYDATA.init_data.maxAdvantage) / 100
          ) {
            advantage_Money =
              (car_Value * MYDATA.init_data.maxAdvantage) / 100;
          }
        } else {
          advantage_Money =
            (car_Value * MYDATA.init_data.maxAdvantage) / 100;
        }
        $landingTimeSlider.val((advantage_Money / car_Value) * 100);
        $landingTimePercentBoxInput.val(
          Math.round((advantage_Money * 100) / car_Value)
        );
        adavatangePercentMax09 = advantage_Percent / 100;
        $("#footer-imprumut").html(car_Value - advantage_Money);

        adavatangePercentMax09 = advantage_Money / car_Value;

        selectLogic();
      }, 1000));

      $landingTimeMoneyBoxInput.keypress(e => {
        selectLogic();
        return isNumberKey(e);
      });

      $landingTimeMoneyBoxInput.on("change", function () {
        $(this).val(advantage_Money);
        $("#footer-imprumut").html(car_Value - advantage_Money);
        selectLogic();
      });

      $landingTimePercentBoxInput.on("input", function () {
        advantage_Percent = Number(this.value);
        advantage_Money = (car_Value * advantage_Percent) / 100;
        $landingTimeSlider.val(this.value);
        $landingTimeMoneyBoxInput.val(advantage_Money);
        adavatangePercentMax09 = advantage_Percent / 100;
        $("#footer-imprumut").html(car_Value - advantage_Money);
      });

      $landingTimePercentBoxInput.keypress(e => {
        return isNumberKey(e);
      });

      $landingTimePercentBoxInput.on("change", function () {
        advantage_Percent = Number(this.value);

        if (advantage_Percent < MYDATA.init_data.minAdvantage) {
          advantage_Percent = MYDATA.init_data.minAdvantage;
        } else if (advantage_Percent > MYDATA.init_data.maxAdvantage) {
          advantage_Percent = MYDATA.init_data.maxAdvantage;
        }

        adavatangePercentMax09 = advantage_Percent / 100;

        $landingTimeSlider.val(advantage_Percent);
        $landingTimeMoneyBoxInput.val(
          (advantage_Percent * car_Value) / 100
        );

        $(this).val(advantage_Percent);

        selectLogic();
      });

      $termenImprumutRangeInput.on("input", function () {
        months = parseInt(this.value);
        $termenImprumutBoxInput.val(months);
        $("#footer-termen-imprumut").html(months);
        selectLogic();
      });

      function checkMonthsMinMaxValue(months) {
        if (months) {
          if (months < MYDATA.init_data.termen_imprumut_min) {
            months = MYDATA.init_data.termen_imprumut_min;
          } else if (months > MYDATA.init_data.termen_imprumut_max) {
            this.value = MYDATA.init_data.termen_imprumut_max;
            months = MYDATA.init_data.termen_imprumut_max;
          }
        } else {
          months = MYDATA.init_data.termen_imprumut_min;
        }

        return months;
      }

      $termenImprumutBoxInput.on("input", function () {
        months = this.value;
        var roundVar = Math.round(months / 5) * 5;
        var myfunction = checkMonthsMinMaxValue(months);

        checkMonthsMinMaxValue(months);
        months = myfunction;
        if (months % 5 === 0) {
          checkMonthsMinMaxValue(months);
        } else {
          var a = checkMonthsMinMaxValue(roundVar);
          months = a;
        }

        $("#input_rangevalue_landing-time").val(months);
        $("#footer-termen-imprumut").html(months);
        selectLogic();
      });

      $termenImprumutBoxInput.keypress(e => {
        selectLogic();
        return isNumberKey(e);
      });

      $termenImprumutBoxInput.on("change", function () {
        this.value = months;
        selectLogic();
      });

      function initDataDisplay() {
        $("#output-rangevalue_car-price").trigger("input");
        $("#input_rangevalue_landing-time").trigger("input");
      }

      function calcMonthlyCommission(
        currentObj,
        imprumutBani
      ) {
        let type = 'absolute';
        let tempMonthlyCommission = currentObj.comision_lunar;

        if (currentObj && currentObj.comision_lunar_type) {
          type = currentObj.comision_lunar_type.values;
        }

        if (type === 'percent') {
          tempMonthlyCommission = imprumutBani * currentObj.comision_lunar / 100;
        }


        if (currentObj.comision_lunar_min !== undefined) {
          if (tempMonthlyCommission < currentObj.comision_lunar_min) {
            tempMonthlyCommission = currentObj.comision_lunar_min;
          }
        }

        if (currentObj.comision_lunar_max !== undefined) {
          if (tempMonthlyCommission > currentObj.comision_lunar_max) {
            tempMonthlyCommission = currentObj.comision_lunar_max;
          }
        }

        return tempMonthlyCommission;
      }

      function calcGrantingCommission(
        currentObj,
        imprumutBani
      ) {
        let tempGrantingCommission = imprumutBani * currentObj.comision_de_acordare;

        if (currentObj.comision_de_acordare_min !== undefined) {
          if (tempGrantingCommission < currentObj.comision_de_acordare_min) {
            tempGrantingCommission = currentObj.comision_de_acordare_min;
          }
        }

        if (currentObj.comision_de_acordare_max !== undefined) {
          if (tempGrantingCommission > currentObj.comision_de_acordare_max) {
            tempGrantingCommission = currentObj.comision_de_acordare_max;
          }
        }
        let grantingCents = ( (tempGrantingCommission * 100 ) % currentObj.comision_de_acordare_luni ) / 100;
        grantGlobal = tempGrantingCommission - grantingCents;
        return grantGlobal;
      }

      function amountToBeRepaid(
        monthlyRate,
        totalMonths,
        grantingCommission,
        imprumutValue,
        carPrice,
        minimGrantingCommission,
        totalCommision
      ) {

        return Number(monthlyRate) * Number(totalMonths) + Number(grantingCommission);
      }


      function calculateRate(
        totalMonths,
        monthlyRate,
        imprumutValue,
        monthlyCommission
      ) {
        return (
          (imprumutValue *
            ((monthlyRate / 12) *
              Math.pow(1 + monthlyRate / 12, totalMonths))) /
          (Math.pow(1 + monthlyRate / 12, totalMonths) - 1) +
          monthlyCommission
        );
      }

      function calcMaxAmount() {
        var maxMonthlyRate = 0;
        var maxMonthlyComm = 0;
        var maxAccordingCommision = 0;
        var borrowedMoney =
          MYDATA.init_data.loanMax *
          Public.MakeCalculus.convertEuroToDollar(
            Public.currencies.eur.paymentRate,
            Public.currencies.usd.releaseRate
          );
        if (Public.pageType === 'simple-auto') {
          maxMonthlyRate = Math.max(
            MYDATA.uzat.rata_lunara ? MYDATA.uzat.rata_lunara[
              MYDATA.init_data.termen_imprumut_max
              ] : 0,
            MYDATA.nou.rata_lunara ? MYDATA.nou.rata_lunara[
              MYDATA.init_data.termen_imprumut_max
              ] : 0
          );
          maxMonthlyComm = Math.max(
            MYDATA.uzat.comision_lunar ? MYDATA.uzat.comision_lunar : 0,
            MYDATA.nou.comision_lunar ? MYDATA.nou.comision_lunar : 0
          );
          maxAccordingCommision = Math.max(
            MYDATA.uzat.comision_de_acordare,
            MYDATA.nou.comision_de_acordare
          );
        } else {
          maxMonthlyRate = Math.max(
            MYDATA.uzat.uzat_standart.rata_lunara[
              MYDATA.init_data.termen_imprumut_max
              ],
            MYDATA.uzat.uzat_mini.rata_lunara[
              MYDATA.init_data.termen_imprumut_max
              ],
            MYDATA.uzat.uzat50.rata_lunara[
              MYDATA.init_data.termen_imprumut_max
              ],
            MYDATA.nou.nou_standart.rata_lunara[
              MYDATA.init_data.termen_imprumut_max
              ],
            MYDATA.nou.nou50.rata_lunara[
              MYDATA.init_data.termen_imprumut_max
              ]
          );
          maxMonthlyComm = Math.max(
            MYDATA.uzat.uzat_standart.comision_lunar,
            MYDATA.uzat.uzat_mini.comision_lunar,
            MYDATA.uzat.uzat50.comision_lunar,
            MYDATA.nou.nou_standart.comision_lunar,
            MYDATA.nou.nou50.comision_lunar
          );
          maxAccordingCommision = Math.max(
            MYDATA.uzat.uzat_standart.comision_de_acordare,
            MYDATA.uzat.uzat_mini.comision_de_acordare,
            MYDATA.uzat.uzat50.comision_de_acordare,
            MYDATA.nou.nou_standart.comision_de_acordare,
            MYDATA.nou.nou50.comision_de_acordare
          );
        }

        var grant = borrowedMoney * maxAccordingCommision;
        if (grant < MYDATA.init_data.comision_de_acordare_min) {
          grant = MYDATA.init_data.comision_de_acordare_min;
        }

        var rataLunara = calculateRate(
          MYDATA.init_data.termen_imprumut_max,
          maxMonthlyRate,
          borrowedMoney,
          maxMonthlyComm
        );
        var sumTotal = amountToBeRepaid(
          rataLunara,
          MYDATA.init_data.termen_imprumut_max,
          maxAccordingCommision,
          borrowedMoney,
          MYDATA.init_data.car_cost,
        );


        sumTotal *= 0.001;
        sumTotal = Math.round(sumTotal);


        if (sumTotal % 2 !== 0) {
          sumTotal += 1;
        }

        return 50;
      }

      function selectLogic() {
        car_Value = Number(car_Value);
        advantage_Money = Number(advantage_Money);
        months = Number(months);
        var currentObj;
        var selectorCar = $(".left-car > .background-image");
        if (selectorCar.hasClass("inactive")) {
          if (Public.pageType === 'simple-auto') {
            currentObj = MYDATA.uzat;
            makeAllCalculus(
              car_Value,
              adavatangePercentMax09,
              advantage_Money,
              months,
              currentObj.rata_lunara[months],
              currentObj.comision_lunar,
              currentObj.comision_de_acordare,
              currentObj.comision_de_acordare_min,
              currentObj
            );
          } else {
            var imprumutValue = document.getElementById("imprumut-value");
            imprumutValue = imprumutValue.innerHTML.replaceAll(" ", '').replaceAll("MDL", "L");
            imprumutValue = Number(imprumutValue.substring(0, imprumutValue.length - 1));
            if (imprumutValue >= 0) {
              // switch car_Value by loanValue
              // if (car_Value < 4000) {
              if (adavatangePercentMax09 >= 0.4) {
                currentObj = MYDATA.uzat.uzat50;
                makeAllCalculus(
                  car_Value,
                  adavatangePercentMax09,
                  advantage_Money,
                  months,
                  currentObj.rata_lunara[months],
                  currentObj.comision_lunar,
                  currentObj.comision_de_acordare,
                  currentObj.comision_de_acordare_min,
                  currentObj
                );
              } else {
                currentObj = MYDATA.uzat.uzat_standart;
                makeAllCalculus(
                  car_Value,
                  adavatangePercentMax09,
                  advantage_Money,
                  months,
                  currentObj.rata_lunara[months],
                  currentObj.comision_lunar,
                  currentObj.comision_de_acordare,
                  currentObj.comision_de_acordare_min,
                  currentObj
                );
              }
            }
          }
        } else {
          if (Public.pageType === 'simple-auto') {
            currentObj = MYDATA.nou;
            makeAllCalculus(
              car_Value,
              adavatangePercentMax09,
              advantage_Money,
              months,
              currentObj.rata_lunara[months],
              currentObj.comision_lunar,
              currentObj.comision_de_acordare,
              currentObj.comision_de_acordare_min,
              currentObj
            );
          } else {
            if (adavatangePercentMax09 >= 0.25) {
              currentObj = MYDATA.nou.nou50;
              makeAllCalculus(
                car_Value,
                adavatangePercentMax09,
                advantage_Money,
                months,
                currentObj.rata_lunara[months],
                currentObj.comision_lunar,
                currentObj.comision_de_acordare,
                currentObj.comision_de_acordare_min,
                currentObj
              );
            } else {
              currentObj = MYDATA.nou.nou_standart;
              makeAllCalculus(
                car_Value,
                adavatangePercentMax09,
                advantage_Money,
                months,
                currentObj.rata_lunara[months],
                currentObj.comision_lunar,
                currentObj.comision_de_acordare,
                currentObj.comision_de_acordare_min,
                currentObj
              );
            }
          }
        }
        if (currentObj) {
          $("#footer-rata-dobinda-fixa").html(
            (currentObj.rata_lunara[months] * 100).toFixed(2)
          );
        }
      }

      function checkMinValOfAdvantage(carPrice, advantageMoney, eurPaymentRate, usdReleaseRate) {
        var maxLoan = MYDATA.init_data.loanMax;
        var minAdvantageMoney = carPrice - maxLoan;
        var minAdvantagePercent = (minAdvantageMoney / carPrice) * 100;
        if (minAdvantagePercent < 10) {
          setMin(MYDATA.init_data.minAdvantage);
        } else {
          setMin(minAdvantagePercent);
        }

        function setMin(min) {
          $landingTimeMinVal.html(min.toFixed(0) + "%");
          $landingTimeSlider.attr({
            min: Math.round(min)
          });

          if (min >= parseInt($landingTimePercentBoxInput.val())) {
            $landingTimePercentBoxInput.val(Math.round(min));
            $landingTimeMoneyBoxInput.val(
              Math.round((carPrice * min) / 100 * usdReleaseRate / eurPaymentRate)
            );

            $landingTimeSlider.val(min);
            $landingTimeSlider.trigger("click");
          }
        }

        return minAdvantageMoney;

      }

      function checkMaxValOfAdvantage(carPrice, advantageMoney, eurPaymentRate, usdReleaseRate) {
        var minLoan = MYDATA.init_data.loanMin;
        var maxAdvantageMoney = carPrice - minLoan;
        var maxAdvantagePercent = (maxAdvantageMoney / carPrice) * 100;
        if (maxAdvantagePercent > MYDATA.init_data.maxAdvantage) {
          setMax(MYDATA.init_data.maxAdvantage);
        } else {
          setMax(maxAdvantagePercent);
        }

        function setMax(max) {
          $landingTimeMaxVal.html(max.toFixed(0) + "%");
          $landingTimeSlider.attr({
            max: Math.round(max)
          });
          if (max <= parseInt($landingTimePercentBoxInput.val())) {
            $landingTimePercentBoxInput.val(Math.round(max));
            $landingTimeMoneyBoxInput.val(
              Math.round((carPrice * max) / 100 * usdReleaseRate / eurPaymentRate)
            );

            $landingTimeSlider.val(max);
            $landingTimeSlider.trigger("click");
          }
        }

        return maxAdvantageMoney;

      }

      function makeAllCalculus(carPrice, advantageInPercent, advantageInMoney, totalMonths, monthlyRate, monthlyCommission, grantingCommission, grantingCommissionMinValue, currentObj) {
        var advantageInMoneyOriginal = Number(advantageInMoney);

        var carPriceOriginal = carPrice;
        var carPriceEuro;
        var maxLoan = MYDATA.init_data.loanMax;
        var minLoan = MYDATA.init_data.loanMin;
        if (currencyWithNoSpace === 'EUR' || currencyWithNoSpace === '€') {
          carPrice *= Public.MakeCalculus.convertEuroToDollar(Public.currencies.eur.paymentRate, Public.currencies.usd.paymentRate);
          advantageInMoney *= Public.MakeCalculus.convertEuroToDollar(Public.currencies.eur.paymentRate, Public.currencies.usd.paymentRate);
          carPriceEuro = carPrice * Public.currencies.eur.paymentRate;
        } else if (currencyWithNoSpace === 'MDL') {
          carPrice /= Public.currencies.usd.paymentRate
          advantageInMoney /= Public.currencies.usd.paymentRate
          carPriceEuro = carPrice;
        } else {
          carPrice *= Public.currencies.usd.paymentRate / Public.currencies.usd.releaseRate
          advantageInMoney *= Public.currencies.usd.paymentRate / Public.currencies.usd.releaseRate
          carPriceEuro = carPrice * Public.currencies.usd.paymentRate;
        }
        var minAdvantageMoneyUSD = checkMinValOfAdvantage(carPrice, advantageInMoney, Public.currencies.eur.paymentRate, Public.currencies.usd.releaseRate);
        var maxAdvantageMoneyUSD = checkMaxValOfAdvantage(carPrice, advantageInMoney, Public.currencies.eur.paymentRate, Public.currencies.usd.releaseRate);
        var imprumutBani = carPrice - advantageInMoney;
        if (imprumutBani > maxLoan) {
          imprumutBani = carPrice - minAdvantageMoneyUSD
        }
        if (imprumutBani < minLoan) {
          imprumutBani = carPrice - maxAdvantageMoneyUSD
        }
        grantingCommission = calcGrantingCommission(currentObj, imprumutBani);

        monthlyCommission = calcMonthlyCommission(currentObj, imprumutBani);


        let rates = calculateRatesList (currentObj, totalMonths, grantingCommission, monthlyRate, monthlyCommission, imprumutBani)
        let ratesPrev = calculateRatesList (currentObj, ((totalMonths > 5) ? (totalMonths - 5) : (totalMonths + 10)), grantingCommission, currentObj.rata_lunara[((totalMonths > 5) ? (totalMonths - 5) : (totalMonths + 10))], monthlyCommission, imprumutBani)
        let ratesNext = calculateRatesList (currentObj, ((totalMonths < 56) ? (totalMonths + 5) : 50), grantingCommission, currentObj.rata_lunara[((totalMonths < 56) ? (totalMonths + 5) : 50)], monthlyCommission, imprumutBani)
        var rataLunara = currentObj.comision_de_acordare_luni ?  parseFloat(rates[currentObj.comision_de_acordare_luni + 1].monthRate) : parseFloat(rates[1].monthRate);
        var rataLunaraPrev = currentObj.comision_de_acordare_luni ?  parseFloat(ratesPrev[currentObj.comision_de_acordare_luni + 1].monthRate) : parseFloat(ratesPrev[1].monthRate);
        var rataLunaraNext = currentObj.comision_de_acordare_luni ?  parseFloat(ratesNext[currentObj.comision_de_acordare_luni + 1].monthRate) : parseFloat(ratesNext[1].monthRate);

        let totalLoan = 0;
        let totalInterest = 0;
        let totalPaymentCommission = 0;
        let paymentWithoutInitialCommission = 0;
        for (let i =1; i < rates.length; i++) {
          totalLoan += parseFloat(rates[i].loan);
          totalInterest += parseFloat(rates[i].interest);
          totalPaymentCommission += parseFloat(rates[i].commission);
          paymentWithoutInitialCommission += parseFloat(rates[i].monthRate);
        }



        var sumTotal = 0;
        var sumTotalPrev = 0;
        var sumTotalNext = 0;

        for (let i = 1; i < rates.length; i++) {
          sumTotal += parseFloat(rates[i].monthRate);
        }
        for (let i = 1; i < ratesPrev.length; i++) {
          sumTotalPrev += parseFloat(ratesPrev[i].monthRate);
        }
        for (let i = 1; i < ratesNext.length; i++) {
          sumTotalNext += parseFloat(ratesNext[i].monthRate);
        }

        // calculate commission in dependence of imprumutBani
        var myArr = [];
        var myArrPrev = [];
        var myArrNext = [];
        var daeCascoArr = [];
        var daeCASCO = 0.045;
        if (MYDATA.init_data.cascoPercent) {
          daeCASCO = MYDATA.init_data.cascoPercent;
        }

        speedometerValue = sumTotal;

        for (let i = 0; i < months + 1; i++) {
          if (i === 0) {
            myArr.push(-(imprumutBani));
            daeCascoArr.push(
              -imprumutBani + carPrice * daeCASCO
            );
          } else {
            myArr.push(rates[i].monthRate);
            daeCascoArr.push(rates[i].monthRate);
          }

        }

        for (let i = 0; i < ((totalMonths > 5) ? (totalMonths - 5) : (totalMonths + 10)) + 1; i++) {
          if (i == 0) {
            myArrPrev.push(-(imprumutBani));
          } else {
            myArrPrev.push(ratesPrev[i].monthRate);
          }

        }

        for (let i = 0; i < ((totalMonths < 56) ? (totalMonths + 5) : 50) + 1; i++) {
          if (i == 0) {
            myArrNext.push(-(imprumutBani));
          } else {
            myArrNext.push(ratesNext[i].monthRate);
          }
        }

        var daeVal = Public.MakeCalculus.calcDAE(myArr);
        var daeValPrev = Number(Public.MakeCalculus.calcDAE(myArrPrev).toFixed(2));
        var daeValNext = Number(Public.MakeCalculus.calcDAE(myArrNext).toFixed(2));

        var daeCASCOVal = Public.MakeCalculus.calcDAE(daeCascoArr);
        daeVal = daeVal.toFixed(2);
        daeCASCOVal = daeCASCOVal.toFixed(2);

        daeVal = Number(daeVal);
        daeCASCOVal = Number(daeCASCOVal);

        $("#footer-dae").html(daeVal);
        var dobinda = totalInterest;

        drowDataInSpeedometer(
          sumTotal,
          imprumutBani,
          dobinda,
          totalPaymentCommission
        );

        updateSpeedometerCalculator(
          dobinda,
          imprumutBani,
          totalPaymentCommission,
          rataLunara,
          grantingCommission,
          currentObj.comision_de_acordare_luni,
          monthlyCommission,
          monthlyRate
        );
        $("#total-loan-cost").html((sumTotal - imprumutBani).toFixed(2))
        $("#footer-monthly-rate").html((rataLunara).toFixed(2))

        var pdfTotalAmountPaidByCustomerToSupplier;

        if (currencyWithNoSpace === 'MDL') {
          if (currentObj.comision_de_acordare_luni) {
            pdfTotalAmountPaidByCustomerToSupplier =
              advantageInMoneyOriginal
          } else {
            pdfTotalAmountPaidByCustomerToSupplier =
              advantageInMoneyOriginal + grantingCommission;
          }

        } else if (currencyWithNoSpace === 'EUR' || currencyWithNoSpace === '€') {
          if (currentObj.comision_de_acordare_luni) {
            pdfTotalAmountPaidByCustomerToSupplier =
              advantageInMoneyOriginal *
              Public.currencies.eur.paymentRate;
          } else {
            pdfTotalAmountPaidByCustomerToSupplier =
              advantageInMoneyOriginal *
              Public.currencies.eur.paymentRate +
              grantingCommission * Public.currencies.usd.releaseRate;
          }

        } else {
          if (currentObj.comision_de_acordare_luni) {
            pdfTotalAmountPaidByCustomerToSupplier =
              advantageInMoneyOriginal *
              Public.currencies.usd.paymentRate;
          } else {
            pdfTotalAmountPaidByCustomerToSupplier =
              advantageInMoneyOriginal *
              Public.currencies.usd.paymentRate +
              grantingCommission * Public.currencies.eur.releaseRate;
          }

        }

        var pdfAmountPaidBySponsor;
        if (currencyWithNoSpace === 'MDL') {
          pdfAmountPaidBySponsor =
            carPriceOriginal - pdfTotalAmountPaidByCustomerToSupplier;
        } else if (currencyWithNoSpace === 'EUR' || currencyWithNoSpace === '€') {
          pdfAmountPaidBySponsor =
            carPriceEuro - pdfTotalAmountPaidByCustomerToSupplier
        } else {
          pdfAmountPaidBySponsor =
            carPriceOriginal * Public.currencies.usd.paymentRate - pdfTotalAmountPaidByCustomerToSupplier;
        }
        var cashWithdrawalFeeMoney =
          (cashWithdrawalFeePercent * pdfAmountPaidBySponsor) / 100;

        var amountPaidByClientMD =
          pdfAmountPaidBySponsor - cashWithdrawalFeeMoney;

        var date = new Date();
        var monthNames = [];
        var creditTypeLang = "";
        if (language === 'ro') {
          monthNames = [
            "Ianuarie",
            "Februarie",
            "Martie",
            "Aprilie",
            "Mai",
            "Iunie",
            "Iulie",
            "August",
            "Septembrie",
            "Octombrie",
            "Noiembrie",
            "Decembrie"
          ];
          creditTypeLang = "Procurare Auto"
        } else {
          monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
          creditTypeLang = "Приобретение авто"
        }
        var pdfGenerateData = {
          grantingCommissionPayInMonths: currentObj.comision_de_acordare_luni ? currentObj.comision_de_acordare_luni : null,
          creditType: creditTypeLang,
          priceOfTheGood: carPriceOriginal.toFixed(2),
          lendingTimeMonths: totalMonths,
          borrowAmount: imprumutBani.toFixed(2),
          internActiveCurrency: getCurrencyLetterValue(),
          ownContributionPercent: Math.round(
            adavatangePercentMax09 * 100
          ),
          ownContributionMoney: advantageInMoneyOriginal.toFixed(2),
          grantingCommissionPercent: (
            grantingCommission * 100
          ).toFixed(2),
          grantingCommissionMoney: grantingCommission.toFixed(2),
          euroPaymentRate: Number(Public.currencies.eur.paymentRate).toFixed(2),
          euroReleaseRate: Number(Public.currencies.eur.releaseRate).toFixed(2),
          usdReleaseRate: Number(Public.currencies.usd.releaseRate).toFixed(2),
          // usdPaymentRate: Number(Public.currencies.usd.paymentRate).toFixed(2),
          usdPaymentRate: Number(Public.currencies.usd.releaseRate).toFixed(2),
          minGrantingCommission: grantingCommissionMinValue,
          totalAmountPaidByCustomerToSupplier: pdfTotalAmountPaidByCustomerToSupplier.toFixed(2),
          fixedAnnualInterestRate: (monthlyRate * 100).toFixed(2),
          monthlyServiceFee: monthlyCommission.toFixed(2),
          monthlyServiceType: getCurrencyLetterValue(),
          amountPaidBySponsor: pdfAmountPaidBySponsor.toFixed(2),
          insuranceCASCO: daeCASCO * 100,
          daePercent: daeVal,
          daeWithCasco: daeCASCOVal,
          cashWithdrawalFeePercent: cashWithdrawalFeePercent,
          cashWithdrawalFeeMoney: cashWithdrawalFeeMoney.toFixed(2),
          amountPaidByClientMD: amountPaidByClientMD.toFixed(2),
          // amountPaidByClientUSD: (
          //   amountPaidByClientMD / Public.currencies.eur.paymentRate
          // ).toFixed(2),
          montlyRate: rataLunara.toFixed(2),
          monthlyRateGranting: rates ? rates[1].monthRate : null,
          finalTotalAmountPaidByCustomer: sumTotal.toFixed(2),
          totalLoanCost: (sumTotal - imprumutBani).toFixed(2),
          borrowYear: date.getFullYear(),
          borrowDate: date.getDate(),
          borrowMonthName: monthNames[date.getMonth()],
          supplierCurrency: currencyWithNoSpace,
          supplierCurrencyRate: currencyWithNoSpace === 'EUR' || currencyWithNoSpace === '€' ? Number(Public.currencies.eur.paymentRate).toFixed(2) : (currencyWithNoSpace === 'MDL' ? 1 : Number(Public.currencies.usd.paymentRate).toFixed(2)),
          loanRates: rates,
          totalLoan: totalLoan.toFixed(2),
          totalInterest: totalInterest.toFixed(2),
          totalCommission: totalPaymentCommission.toFixed(2),
          paymentWithoutInitialCommission: paymentWithoutInitialCommission.toFixed(2),
          isClientPrecontract: true,
          representativeExamples: [
            {
              montlyRate: rataLunara.toFixed(2),
              lendingTimeMonths: totalMonths,
              finalTotalAmountPaidByCustomer: sumTotal.toFixed(2),
              fixedAnnualInterestRate: (monthlyRate * 100).toFixed(2),
              daePercent: daeVal
            },
            {
              montlyRate: rataLunaraPrev.toFixed(2),
              lendingTimeMonths: ((totalMonths > 5) ? (totalMonths - 5) : (totalMonths + 10)),
              finalTotalAmountPaidByCustomer: sumTotalPrev.toFixed(2),
              fixedAnnualInterestRate: (currentObj.rata_lunara[((totalMonths > 5) ? (totalMonths - 5) : (totalMonths + 10))] * 100).toFixed(2),
              daePercent: daeValPrev
            },
            {
              montlyRate: rataLunaraNext.toFixed(2),
              lendingTimeMonths: ((totalMonths < 56) ? (totalMonths + 5) : 50),
              finalTotalAmountPaidByCustomer: sumTotalNext.toFixed(2),
              fixedAnnualInterestRate: (currentObj.rata_lunara[((totalMonths < 56) ? (totalMonths + 5) : 50)] * 100).toFixed(2),
              daePercent: daeValNext
            }
          ]
        };
        pdfGenerateData.representativeExamples = pdfGenerateData.representativeExamples.sort((a, b) => (a.lendingTimeMonths > b.lendingTimeMonths) ? 1 : -1)

        var cascoLimit = 8000;
        if (MYDATA.init_data.cascoLimit) {
          cascoLimit = MYDATA.init_data.cascoLimit;
        }
        if (imprumutBani > cascoLimit) {
          pdfGenerateData.isCasco = true;
        }

        var pdf = Public.MakeCalculus.generateLinkPDF(pdfGenerateData);
        var paymentPdf = Public.MakeCalculus.generatePaymentLinkPDF(pdfGenerateData);

        $(".pdfPrecontract").attr("href", pdf);
        $(".pdfPayment").attr("href", paymentPdf);

        Public.PrecontratDataAuto = pdfGenerateData;
      }

      function updateSpeedometerCalculator(
        dobindaValue,
        imprumutValue,
        comisionValue,
        rataValue,
        grantingCommission,
        grantingCommissionMonths,
        monthlyCommission,
        monthlyRate
      ) {
        if (currencyWithNoSpace === 'MDL') {
          dobindaValue /= Public.currencies.usd.paymentRate
          imprumutValue /= Public.currencies.usd.paymentRate
          comisionValue /= Public.currencies.usd.paymentRate
          rataValue /= Public.currencies.usd.paymentRate
          grantingCommission /= Public.currencies.usd.paymentRate
          monthlyCommission /= Public.currencies.usd.paymentRate
          monthlyRate /= Public.currencies.usd.paymentRate
        }

        $("div#dobinda-value.amount").html(
          Calculator.MakeCalculus.numberWithCommas(
            Number(dobindaValue.toFixed(0))
          ) + "$"
        );
        var imprumutValueHtml = document.getElementById("imprumut-value");
        imprumutValueHtml = imprumutValueHtml.innerHTML.replaceAll(" ", '');
        imprumutValueHtml = Number(imprumutValueHtml.substring(0, imprumutValueHtml.length - 1));

        $("div#imprumut-value.amount").html(
          Calculator.MakeCalculus.numberWithCommas(
            Number(imprumutValue.toFixed(0))
          ) + "$"
        );
        if (imprumutValueHtml !== Number(imprumutValue.toFixed(0))) {
          selectLogic();
        }
        $("div#comision-value.amount").html(
          Calculator.MakeCalculus.numberWithCommas(
            Number(comisionValue.toFixed(0))
          ) + "$"
        );
        $("div#rata-value.amount").html(
          Calculator.MakeCalculus.numberWithCommas(
            Number(rataValue.toFixed(0))
          ) + "$"
        );

        // UPDATING footer under calculator with Exemplu reprezentativ

        $("#footer-imprumut").html(
          Calculator.MakeCalculus.numberWithCommas(
            Number(imprumutValue.toFixed(0))
          )
        );
        $("#footer-comision-de-acordare").html(grantGlobal.toFixed(2));
        if (MYDATA.init_data.comision_de_acordare_luni) {
          $(".analyse-commission-text").show();
          $("#granting-months").html(MYDATA.init_data.comision_de_acordare_luni);
        } else {
          $(".analyse-commission-text").hide();
        }
        $("#footer-comision-lunar").html(monthlyCommission.toFixed(2));
      }

      function drowDataInSpeedometer(
        totalAmount,
        imprumut,
        dobinda,
        comision
      ) {
        imprumut = imprumut * 0.001;
        comision = comision * 0.001;
        dobinda = dobinda * 0.001;

        if (window.chart) {
          window.chart.axes[2].setTopText(
            Calculator.MakeCalculus.numberWithCommas(
              Number(totalAmount.toFixed(0))
            ) + "$"
          );
          window.chart.axes[2].bands[(0, 0)].setStartValue(0);
          window.chart.axes[2].bands[(0, 0)].setEndValue(imprumut);
          window.chart.axes[2].bands[(0, 1)].setStartValue(imprumut);
          window.chart.axes[2].bands[(0, 1)].setEndValue(
            imprumut + dobinda
          );
          window.chart.axes[2].bands[(0, 2)].setStartValue(
            imprumut + dobinda
          );
          window.chart.axes[2].bands[(0, 2)].setEndValue(
            imprumut + dobinda + comision
          );
          window.chart.axes[2].bands[(0, 3)].setStartValue(
            imprumut + dobinda + comision
          );
          window.chart.axes[2].bands[(0, 3)].setEndValue(
            parseInt(speedometerMaxVal)
          );
          window.chart.arrows[0].setValue(
            imprumut + dobinda + comision
          );
        }
      }

      function loadChart() {
        window.chart = AmCharts.makeChart("chartdiv", {
          theme: "light",
          type: "gauge",
          fontFamily: "'Rubik' , sans-serif",
          // innerRadius: "90%",
          axes: [
            {
              id: "lastaxis",
              endValue: 20,
              gridInside: false,
              inside: false,
              radius: "100%",
              bold: false,
              axisThickness: 0,
              axisAlpha: 1,
              tickAlpha: 1,
              tickColor: "#efefef",
              valueInterval: 2,
              axisColor: "#efefef",
              labelFrequency: 1,
              tickLength: 0,
              color: " #efefef", //color of numbers last circle should be this color #efefef because in this background color is #efefef
              minorTickInterval: 2,
              bands: [
                {
                  color: "#efefef",
                  endValue: 4,
                  innerRadius: "92%",
                  startValue: 0,
                  alpha: 0.1
                },
                {
                  color: "#00b7f4",
                  endValue: 5.6,
                  innerRadius: "92%",
                  startValue: 4,
                  alpha: 1
                },
                {
                  color: "#cacaca",
                  endValue: 6.4,
                  innerRadius: "92%",
                  startValue: 5.6,
                  alpha: 1
                },
                {
                  color: "#efefef",
                  endValue: 8.2,
                  innerRadius: "92%",
                  startValue: 6.4,
                  alpha: 0.1
                },
                {
                  color: "#0476bd",
                  endValue: 11.8,
                  innerRadius: "92%",
                  startValue: 8.2,
                  alpha: 1
                },
                {
                  color: "#efefef",
                  endValue: 13.6,
                  innerRadius: "92%",
                  startValue: 11.8,
                  alpha: 0.1
                },
                {
                  color: "#cacaca",
                  endValue: 14.4,
                  innerRadius: "92%",
                  startValue: 13.4,
                  alpha: 1
                },
                {
                  color: "#f98e3d",
                  endValue: 16,
                  innerRadius: "92%",
                  startValue: 14.4,
                  alpha: 1
                }
              ],
              startAngle: -150,
              endAngle: 150
            },
            {
              id: "middleaxis",
              axisColor: "#cacaca",
              axisThickness: 3,
              gridInside: false,
              inside: false,
              radius: "87%",
              tickColor: "#67b7dc",
              color: "#ffffff",
              endValue: 0,
              innerRadius: "90%",
              startAngle: -150,
              endAngle: 150
            },
            {
              id: "inneraxis",
              radius: "72%",
              endValue: speedometerMaxVal,
              startValue: 0,
              startAngle: -150,
              endAngle: 150,
              topText: "0 $",
              topTextYOffset: 75,
              topTextFontSize: 24,
              axisThickness: 1,
              axisAlpha: 1,
              tickAlpha: 1,
              tickColor: "#cccccc",
              valueInterval: 5,
              axisColor: "#cccccc",
              labelFrequency: 1,
              tickLength: 20,
              color: "#457dc0",
              minorTickInterval: 5,
              labelOffset: 3, // distance between numbers and axis
              bands: [
                {
                  color: "#457dc0",
                  endValue: 8.2,
                  innerRadius: "90%",
                  startValue: 0,
                  alpha: 1
                },
                {
                  color: "#00b7f4",
                  endValue: 9.8,
                  innerRadius: "90%",
                  startValue: 8.2,
                  alpha: 1
                },
                {
                  color: "#f98e3d",
                  endValue: 10.2,
                  innerRadius: "90%",
                  startValue: 9.8,
                  alpha: 1
                },
                {
                  color: "#cacaca",
                  endValue: speedometerMaxVal,
                  innerRadius: "90%",
                  startValue: 10.2,
                  alpha: 1
                }
              ],
              bottomText: "Total de rambursat",
              bottomTextColor: "#000000",
              bottomTextYOffset: -63,
              bottomTextFontSize: 14
            }
          ],
          arrows: [
            {
              color: "#f44e65",
              startValue: 0,
              endValue: speedometerMaxVal,
              startWidth: "4",
              innerRadius: "66%", //44
              radius: "41%", //41
              nailBorderThickness: "50%",
              axis: "inneraxis"
            }
          ],
          export: {
            enabled: false
          },
          listeners: [
            {
              event: "rendered",
              method: function (e) {
                initDataDisplay();
              }
            }
          ]
        });
        if (language === 'ro') {
          window.chart.axes[2].bottomText = "Total de rambursat";
        } else {
          window.chart.axes[2].bottomText = "Подлежит погашению";
        }
      }

      if ($(window).width() <= 991) {
        // initialize data on mobile
        document.getElementById("hidden-mob-btn").click();
        document.querySelectorAll("body")[0].classList.remove("modal-open");
        const modal = document.querySelectorAll(".modal#auto-modalCalculator")[0];
        document.querySelectorAll(".modal-backdrop")[0].style.opacity = 0;
        modal.style.visibility = 'hidden';
        modal.classList.remove("fade");

        setTimeout(() => {
          var btn = document.getElementById("hidden-mob-btn");
          var $dataAttr = $(btn).data("modal-name");
          var $modal = $("#" + $dataAttr + "-modalCalculator");
          $modal.modal("show");
          setTimeout(() => {
            $modal.modal("hide")
            document.querySelectorAll(".modal#auto-modalCalculator")[0].style.visibility = "initial";
          }, 1000)
          $modal.on("hidden.bs.modal", function () {
            if (
              $modal.attr("id") === "auto-modalCalculator"
            ) {
            }
          });
        }, 1)
      }
    },
    InitBusinessCalculator: function () {
      $(document).ready(function () {
        Public.MakeCalculus.validateSendOfferInput();
        Public.MakeCalculus.emailPrecontractOffer();

        var loanSliderTitle = document.getElementById(
          "fourth-section-title"
        );
        Public.calculatorType = "business-calculator";
        Public.fileConfigType = "cu gaj";
        MYDATA = Public.MakeCalculus.checkWhichFile();
        imprumutValue = MYDATA.init_data.imprumut;
        months = MYDATA.init_data.termenul_imprumutului;
        Public.MakeCalculus.initDataDisplay();
        Public.MakeCalculus.initCalculatorInputs();
        let monthlyCommission = Public.MakeCalculus.calculateMonthlyCommission(imprumutValue);
        Public.MakeCalculus.redrawSpeedometerValues(
          true,
          imprumutValue,
          months,
          monthlyCommission,
          MYDATA.details.comision_de_acordare_min_value,
          MYDATA.details.comision_de_acrodare_percent,
          MYDATA.details.rata_dobinzii[months],
          monthlyCommission,
          Public.MakeCalculus.calculateMaxValueOfCalculator()
        );
        Public.MakeCalculus.addItems(4);
        if (language === 'ro') {
          loanSliderTitle.innerHTML = "Cum obții creditul cu gaj";
        } else {
          loanSliderTitle.innerHTML = "Как получить кредит под залог";
        }
        ;

        Public.MakeCalculus.initHiddenCalculator();

        $("#visible-gif-mobile")
          .attr("src", "/images/Calculator-1.gif")
          .css({
            display: "block"
          });

        (function checkUserSelectionBusinessPage() {
          var $selector = $(
            ".business-calculator .calculation-area .business-selector"
          );
          var $leftImg = $("#left-gaj-img");
          var $leftGif = $("#left-gaj-gif");
          var $rightGif = $("#right-gaj-gif");
          var $checkLeft = $(
            " .business-calculator .calculation-area .business-selector .left-gaj"
          ).data("gif-active");
          var $checkRight = $(
            ".business-calculator .calculation-area .business-selector .right-gaj"
          ).data("gif-active");

          $selector.on("click", function (e) {
            if (
              e.target.className === "left-gaj" ||
              e.target.parentNode.className === "left-gaj"
            ) {
              if ($checkLeft === true) {
                if (!$selector.hasClass("gaj-active")) {
                  Public.fileConfigType = "cu gaj";
                  Public.MakeCalculus.initDataDisplay();
                  monthlyCommission = Public.MakeCalculus.calculateMonthlyCommission(imprumutValue);
                  Public.MakeCalculus.redrawSpeedometerValues(
                    true,
                    imprumutValue,
                    months,
                    MYDATA.details.comision_lunar,
                    MYDATA.details
                      .comision_de_acordare_min_value,
                    MYDATA.details
                      .comision_de_acrodare_percent,
                    MYDATA.details.rata_dobinzii[months],
                    MYDATA.details.comision_lunar,
                    Public.MakeCalculus.calculateMaxValueOfCalculator()
                  );
                  Public.MakeCalculus.destroySlickEmptySliderContent();
                  Public.MakeCalculus.addItems(4);
                  Slider.Init("loan");
                  getCurrencySymbol(MYDATA.init_data.currency && MYDATA.init_data.currency.values ? MYDATA.init_data.currency.values : '$');
                  let currencyValue = currencyWithNoSpace;
                  document.querySelectorAll(".custom-currency").forEach(el => {
                    el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + currencyValue;
                  })
                  document.querySelectorAll(".currency-text").forEach(el => {
                    if (currencyValue === "USD" || currencyValue === "$") {
                      el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'USD';
                    } else if (currencyValue === "EUR" || currencyValue === "€") {
                      el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'EUR';
                    } else {
                      el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'MDL';
                    }
                  })
                }

                $leftImg.css("display", "none");
                if (language === 'ro') {
                  loanSliderTitle.innerHTML = "Cum obții creditul cu gaj";
                } else {
                  loanSliderTitle.innerHTML = "Как получить кредит под залог";
                }
                ;
                $selector
                  .removeClass("gaj-inactive")
                  .addClass("gaj-active");
                $leftGif.css("display", "none");
                // $rightGif.attr("src", "");
                $rightGif.attr(
                  "src",
                  "/images/Balance_phase-2.gif"
                );
                $rightGif.css("display", "block");
              }

              $checkLeft = false;
              $checkRight = true;
            } else if (
              e.target.className === "right-gaj" ||
              e.target.parentNode.className === "right-gaj"
            ) {
              if ($checkRight === true) {
                if (!$selector.hasClass("gaj-inactive")) {
                  Public.fileConfigType = "fara gaj";
                  Public.MakeCalculus.initDataDisplay();
                  monthlyCommission = Public.MakeCalculus.calculateMonthlyCommission(imprumutValue);
                  Public.MakeCalculus.redrawSpeedometerValues(
                    false,
                    imprumutValue,
                    months,
                    monthlyCommission,
                    MYDATA.details
                      .comision_de_acordare_min_value,
                    MYDATA.details
                      .comision_de_acrodare_percent,
                    MYDATA.details.rata_dobinzii[months],
                    MYDATA.details.comision_lunar_max,
                    Public.MakeCalculus.calculateMaxValueOfCalculator()
                  );
                  Public.MakeCalculus.destroySlickEmptySliderContent();
                  Public.MakeCalculus.addItems(3);
                  Slider.Init("loan");
                  getCurrencySymbol(MYDATA.init_data.currency && MYDATA.init_data.currency.values ? MYDATA.init_data.currency.values : '$');
                  let currencyValue = currencyWithNoSpace;
                  document.querySelectorAll(".custom-currency").forEach(el => {
                    el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + currencyValue;
                  })
                  document.querySelectorAll(".currency-text").forEach(el => {
                    if (currencyValue === "USD" || currencyValue === "$") {
                      el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'USD';
                    } else if (currencyValue === "EUR" || currencyValue === "€") {
                      el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'EUR';
                    } else {
                      el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'MDL';
                    }
                  })
                }

                $leftImg.css("display", "none");
                loanSliderTitle.innerHTML =
                  "Cum obții creditul fără gaj";
                $selector
                  .removeClass("gaj-active")
                  .addClass("gaj-inactive");
                $rightGif.css("display", "none");
                // $leftGif.attr("src", "");
                $leftGif.attr(
                  "src",
                  "/images/Balance_phase-1.gif"
                );
                $leftGif.css("display", "block");
              }
              $checkRight = false;
              $checkLeft = true;
            }
          });
        })();
      });
    },
    InitNecesitatiCalculator: function () {
      $(document).ready(function () {
        Public.MakeCalculus.validateSendOfferInput();
        Public.MakeCalculus.emailPrecontractOffer();

        var loanTitle = document.getElementById("fourth-section-title");
        if (language === 'ro') {
          loanTitle.innerHTML = "Cum obții creditul cu gaj";
        } else {
          loanTitle.innerHTML = "Как получить кредит под залог";
        }
        ;

        Public.calculatorType = "necesitati-calculator";
        Public.fileConfigType = "cu gaj";
        MYDATA = Public.MakeCalculus.checkWhichFile();
        imprumutValue = MYDATA.init_data.imprumut;
        months = MYDATA.init_data.termenul_imprumutului;
        Public.MakeCalculus.initCalculatorInputs(); // Activate all inputs
        Public.MakeCalculus.initDataDisplay(); // Init all html
        const monthlyCommission = Public.MakeCalculus.calculateMonthlyCommission(imprumutValue);
        Public.MakeCalculus.redrawSpeedometerValues(
          true,
          imprumutValue,
          months,
          monthlyCommission,
          MYDATA.details.comision_de_acordare_min_value,
          MYDATA.details.comision_de_acrodare_percent,
          MYDATA.details.rata_dobinzii[months],
          MYDATA.details.comision_lunar_max,
          Public.MakeCalculus.calculateMaxValueOfCalculator()
        );
        Public.MakeCalculus.addItems(4);

        Public.MakeCalculus.initHiddenCalculator();

        var $parentSelector = $(
          ".necesitati-calculator .calculation-area .necesitati-selector"
        );
        var $leftGaj = $(
          ".necesitati-calculator .calculation-area .necesitati-selector .left-gaj"
        );
        var $rightGaj = $(
          ".necesitati-calculator .calculation-area .necesitati-selector .right-gaj"
        );

        var $carImgInactive = $("#car-inactive-img");
        var $carStartHovering = $("#car-start-hovering");
        var $carHover = $("#car-hovering");
        var $carActive = $("#car-active");

        var $fishImgInactive = $("#fish-inactive-img");
        var $fishStartHovering = $("#fish-start-hovering");
        var $fishHover = $("#fish-hovering");
        var $fishActive = $("#fish-active");

        (function checkUserSelectionNecesitatiPage(params1, params2) {
          var timerLeft, timerRight;

          params1.on("mouseenter", function () {
            randomNumber = new Date().getMilliseconds();
            if ($parentSelector.hasClass("gaj-inactive")) {
              $carImgInactive.css("display", "none");
              $carStartHovering.attr("src", "");
              $carStartHovering.attr(
                "src",
                "/images/car-start_hover-animation.gif"
              );
              $carStartHovering.css("display", "block");
              timerLeft = setTimeout(function () {
                $carStartHovering.css("display", "none");
                $carHover.css("display", "block");
              }, 2000);
            }
          });

          params1.on("mouseleave", function () {
            if ($parentSelector.hasClass("gaj-inactive")) {
              clearTimeout(timerLeft);
              $carStartHovering
                .add($carHover)
                .css("display", "none");
              $carImgInactive.css("display", "block");
            }
          });

          params2.on("mouseenter", function () {
            if ($parentSelector.hasClass("gaj-active")) {
              randomNumber = new Date().getMilliseconds();
              $fishImgInactive.css("display", "none");
              $fishStartHovering.attr("src", "");
              $fishStartHovering.attr(
                "src",
                "/images/fish-start_hover-animation.gif"
              );
              $fishStartHovering.css("display", "block");
              timerRight = setTimeout(function () {
                $fishStartHovering.css("display", "none");
                $fishHover.css("display", "block");
              }, 2000);
            }
          });

          params2.on("mouseleave", function () {
            if ($parentSelector.hasClass("gaj-active")) {
              clearTimeout(timerRight);
              $fishStartHovering
                .add($fishHover)
                .css("display", "none");
              $fishImgInactive.css("display", "block");
            }
          });

          params1.on("touchend click", function (event) {
            if (!$parentSelector.hasClass("gaj-active")) {
              event.stopPropagation();
              event.preventDefault();
              Public.fileConfigType = "cu gaj";
              Public.MakeCalculus.initDataDisplay();

              Public.MakeCalculus.redrawSpeedometerValues(
                true,
                imprumutValue,
                months,
                monthlyCommission,
                MYDATA.details.comision_de_acordare_min_value,
                MYDATA.details.comision_de_acrodare_percent,
                MYDATA.details.rata_dobinzii[months],
                MYDATA.details.comision_lunar,
                Public.MakeCalculus.calculateMaxValueOfCalculator()
              );

              Public.MakeCalculus.destroySlickEmptySliderContent();
              Public.MakeCalculus.addItems(4);
              Slider.Init("loan");
            }
            clearTimeout(timerLeft);
            $carImgInactive
              .add($carStartHovering)
              .add($carHover)
              .add($fishStartHovering)
              .add($fishHover)
              .add($fishActive)
              .css("display", "none");
            $fishImgInactive
              .add($carActive)
              .css("display", "block");
            $parentSelector
              .removeClass("gaj-inactive")
              .addClass("gaj-active");
            if (language === 'ro') {
              loanTitle.innerHTML = "Cum obții creditul cu gaj";
            } else {
              loanTitle.innerHTML = "Как получить кредит под залог";
            }
            getCurrencySymbol(MYDATA.init_data.currency && MYDATA.init_data.currency.values ? MYDATA.init_data.currency.values : '$');
            let currencyValue = currencyWithNoSpace;
            document.querySelectorAll(".custom-currency").forEach(el => {
              el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + currencyValue;
            })
            document.querySelectorAll(".currency-text").forEach(el => {
              if (currencyValue === "USD" || currencyValue === "$") {
                el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'USD';
              } else if (currencyValue === "EUR" || currencyValue === "€") {
                el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'EUR';
              } else {
                el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'MDL';
              }
            })
          });

          params2.on("touchend click", function (event) {
            if (!$parentSelector.hasClass("gaj-inactive")) {
              event.stopPropagation();
              event.preventDefault();
              Public.fileConfigType = "fara gaj";
              Public.MakeCalculus.initDataDisplay();
              const monthlyCommission = Public.MakeCalculus.calculateMonthlyCommission(imprumutValue);
              Public.MakeCalculus.redrawSpeedometerValues(
                false,
                imprumutValue,
                months,
                monthlyCommission,
                MYDATA.details.comision_de_acordare_min_value,
                MYDATA.details.comision_de_acrodare_percent,
                MYDATA.details.rata_dobinzii[months],
                MYDATA.details.comision_lunar_max,
                Public.MakeCalculus.calculateMaxValueOfCalculator()
              );

              Public.MakeCalculus.destroySlickEmptySliderContent();
              Public.MakeCalculus.addItems(3);
              Slider.Init("loan");
            }
            clearTimeout(timerRight);
            $carActive
              .add($carStartHovering)
              .add($carHover)
              .add($fishStartHovering)
              .add($fishHover)
              .add($fishImgInactive)
              .css("display", "none");
            $fishActive
              .add($carImgInactive)
              .css("display", "block");
            $parentSelector
              .removeClass("gaj-active")
              .addClass("gaj-inactive");
            if (language === 'ro') {
              loanTitle.innerHTML = "Cum obții creditul cu gaj";
            } else {
              loanTitle.innerHTML = "Как получить кредит под залог";
            }
            getCurrencySymbol(MYDATA.init_data.currency && MYDATA.init_data.currency.values ? MYDATA.init_data.currency.values : '$');
            let currencyValue = currencyWithNoSpace;
            document.querySelectorAll(".custom-currency").forEach(el => {
              el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + currencyValue;
            })
            document.querySelectorAll(".currency-text").forEach(el => {
              if (currencyValue === "USD" || currencyValue === "$") {
                el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'USD';
              } else if (currencyValue === "EUR" || currencyValue === "€") {
                el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'EUR';
              } else {
                el.innerHTML = el.innerHTML.replaceAll("$", "").replaceAll("€", "").replaceAll("MDL", "").replaceAll("USD", "").replaceAll("EUR", "").replaceAll("L", "") + 'MDL';
              }
            })
          });
        })($leftGaj, $rightGaj);
      });
    }
  };

  return Public;
})();

var TermsConditions = (function () {
  var Public = {
    Init: function () {
      $(
        "#terms-conditions .personal-data .additional-info .article-preview"
      ).on("click", function (e) {
        var $parent = $(this).parent(".additional-info");

        if ($(this).hasClass("active")) {
          $(this)
            .addClass("not-active")
            .removeClass("active");
        } else {
          if ($parent.find(".article-preview").hasClass("active")) {
            $parent
              .find(".article-preview.active")
              .addClass("not-active");
          }

          $parent.find(".article-preview").removeClass("active");

          $(this)
            .removeClass("not-active")
            .addClass("active");
        }
      });
    }
  };

  return Public;
})();

var VisitorPageScrollWatch = (function () {
  var Public = {
    Init: function () {
      $(".visitor-page").scrollTop(0);

      var elemArr = $(".visitor-page .content .step-cell");
      var elementHeightArr = [];

      for (let i = 0; i < elemArr.length; i++) {
        elementHeightArr.push(elemArr[i].clientHeight);
      }

      var lastScrollTop = 0;

      $(window)
        .scroll(function () {
          var scrollTop = $(window).scrollTop();

          var lineToTopOffset = $(
            ".visitor-page .content .blue-vertical-line"
          ).offset().top;

          $(".visitor-page .content .blue-vertical-line").animate(
            {
              height:
                scrollTop -
                lineToTopOffset +
                $(window).height() / 2 +
                "px"
            },
            10
          );

          var scrollDistance = $(window).scrollTop();

          var items = [];

          if ($(window).width() > 768) {
            items = $(
              ".visitor-page .content .step-cell .blue-half-bullet"
            );
          } else {
            items = $(
              ".visitor-page .content .step-cell .left-part .title"
            );
          }

          items.each(function (i) {
            var elementOffset = $(this).offset().top,
              distance = elementOffset - scrollTop;

            if ($(window).height() / 2 >= distance) {
              $(this)
                .closest(".step-cell")
                .addClass("active");
            }
            if ($(window).height() / 2 <= distance) {
              $(this)
                .closest(".step-cell")
                .removeClass("active");
            }
            if (
              scrollTop + $(window).height() ==
              $(document).height()
            ) {
              $(".step-cell").addClass("active");
              $(
                ".visitor-page .content .blue-vertical-line"
              ).animate(
                {
                  height:
                    $(".step-cell")
                      .last()
                      .offset().top -
                    lineToTopOffset +
                    $(".step-cell")
                      .last()
                      .height() +
                    "px"
                },
                10
              );
            }
          });
        })
        .scroll();
    }
  };

  return Public;
})();

function calculateRatesList (currentObj, totalMonths, grantingCommission, monthlyRate, monthlyCommission, imprumutBani) {
  let credit, anuity, r, loanSold, sum;
  const [totalDaysInThisMonth, totalDaysInThisYear] = countDaysYear(totalMonths);
  let rates = [{
    sold: imprumutBani.toFixed(2),
  }];
  sum = round2(imprumutBani);
  let grantingCommissionDuration = currentObj.comision_de_acordare_luni;

  let maxAnuity = sum;
  let minAnuity = 0;

  const diffAnuity = function () {
    return maxAnuity - minAnuity;
  };
  const avgAnuity = function () {
    return (maxAnuity + minAnuity) / 2;
  };
  while (diffAnuity() > 0.01) {
    loanSold = sum;
    rates = [{
      sold: imprumutBani.toFixed(2),
    }];
    anuity = avgAnuity();

    for (let i = 0; i < totalMonths; i++) {
      let currentMonthlyCommission = monthlyCommission;
      const currentCDR =
        (monthlyRate / totalDaysInThisYear[i]) *
        totalDaysInThisMonth[i];

      const dobanda = parseFloat((loanSold * currentCDR).toFixed(2));
      credit = anuity - dobanda;

      loanSold -= credit;
      if (grantingCommissionDuration && grantingCommissionDuration > i && grantingCommission) {
        currentMonthlyCommission = round2( grantingCommission / grantingCommissionDuration);
      }
      r = anuity + currentMonthlyCommission + (loanSold < 5 ? loanSold : 0);
      loanSold = round2(loanSold);
      rates.push({
        rate: rates.length,
        sold: parseFloat(loanSold).toFixed(2),
        loan: credit.toFixed(2),
        interest: dobanda.toFixed(2),
        commission: currentMonthlyCommission.toFixed(2),
        monthRate: r.toFixed(2)
      });
    }
    if (loanSold < 0) {
      maxAnuity = anuity;
    } else {
      minAnuity = anuity;
    }
    rates[rates.length-1].monthRate =  (parseFloat(rates[rates.length-1].monthRate) + parseFloat(rates[rates.length-1].sold)).toFixed(2);
    rates[rates.length-1].sold = 0;
  }
  return rates;
}


function round2(val) {
  val = Math.round(val * 10000) / 10000;
  val = Math.round(val * 1000) / 1000;
  return Math.round(val * 100) / 100;
}

getLastDigit = t => {
  return parseInt(t.toString().slice(-1));
};

getFirstDigits = t => {
  return parseInt(t.toString().slice(0, -1));
};

isMultipleOf5 = t => {
  return [0, 5].reduce((res, curr) => {
    return (res = res || curr === getLastDigit(t));
  }, false);
};

isBetween0and5 = t => {
  const _t = getLastDigit(t);
  return _t < 5;
};

isBetween5and9 = t => {
  const _t = getLastDigit(t);
  return _t => 5 && _t <= 9;
};

appendDigit = (t, d) => {
  return parseInt(getFirstDigits(t).toString() + d.toString());
};

getLeft = t => {
  if (t >= 10) {
    if (isBetween0and5(t)) return appendDigit(t, 0);
    else return appendDigit(t, 5);
  } else {
    if (t < 5) return 0;
    else return 5;
  }
};

getSecondRightMostDigit = t => {
  return parseInt(t.toString().slice(-2, -1));
};

incrementSecondDigit = t => {
  return t + 10;
};

getRight = t => {
  if (t < 5) return 5;
  else if (t < 10) return 10;
  else if (isBetween0and5(t)) return appendDigit(t, 5);
  else return appendDigit(incrementSecondDigit(t), 0);
};

function range(c, m) {
  var current = c || 1,
    last = m,
    delta = 2,
    left = getLeft(c),
    right = getRight(c),
    range = [],
    rangeWithEllipsis = [],
    l,
    t;

  var rightBoundary = right < 5 ? 5 : right;
  for (var i = left; i < rightBoundary; ++i) {
    if (i < m && i > 0) range.push(i);
  }
  range.push(m);

  for (var i of range) {
    if (l) {
      if (i - l === 2) {
        t = l + 1;
        rangeWithEllipsis.push(t);
      } else if (i - l !== 1) {
        rangeWithEllipsis.push("...");
      }
    }
    rangeWithEllipsis.push(i);
    l = i;
  }
  return rangeWithEllipsis;
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

function countDaysYear (countDaysCount) {
  const countDays = [];
  const today = moment();
  let currentDate,
    lastDate = today.clone();
  const todayDay = today.date();
  const daysInThisYear = [];
  for (let i = 1; i <= countDaysCount; i++) {
    currentDate = lastDate.clone();
    lastDate = today
      .clone()
      .add('months', i)
      .date(todayDay);

    if (lastDate.day() === 0) {
      lastDate.add('days', 1);
    } else if (lastDate.day() === 6) {
      lastDate.add('days', 2);
    }

    countDays[i - 1] = lastDate.diff(currentDate, 'days');
    daysInThisYear[i - 1] =
      lastDate
        .clone()
        .endOf('year')
        .diff(lastDate.clone().startOf('year'), 'days') + 1;
  }
  return [countDays, daysInThisYear];
};

var UserFeedback = (function () {
  var Public = {
    Init: function () {
      var $name = $("#name");
      var $emaiOrPhone = $("#phone-email");
      var $userFeedback = $("#feedback");
      var $submitBtn = $(".send-opinion-btn");

      $name.on("input", function () {
        validate();
      });

      $emaiOrPhone.on("input", function () {
        validate();
      });

      $userFeedback.on("input", function () {
        validate();
      });

      function validate() {
        if (
          $name.val().length > 0 &&
          $emaiOrPhone.val().length > 5 &&
          $userFeedback.val().length > 10
        ) {
          $(".send-opinion-btn").css({
            "background-color": "#f39a17"
          });

          $submitBtn.prop("disabled", false);
        } else {
          $(".send-opinion-btn").css({
            "background-color": "#e2e2e2"
          });
          $submitBtn.prop("disabled", true);
        }
      }

      $("#user-suggestions").on("submit", function (e) {
        e.preventDefault();

        var clientFeedback = {
          name: $name.val(),
          phoneOrEmail: $emaiOrPhone.val(),
          content: $userFeedback.val()
        };

        $.ajax({
          crossDomain: true,
          type: "post",
          url: "/api/public/opinions",
          data: JSON.stringify(clientFeedback),
          contentType: "application/json; charset=utf-8",
          success: function (succes) {
          },
          error: function (a, status, error) {
          },
          complete: function () {
          }
        });

        $("#feedbackModal").modal("show");
        $(this)[0].reset();
      });
    }
  };

  return Public;
})();

var April = (function () {
  var Public = {
    index: 0,
    data: [],
    Init: function () {
      sessionStorage.setItem('april', true);
      var data = this.getData();
      $('.april-next').on('click', function () {
        April.index++;
        $('#april-modal .april-next').html('');
        $('#april-modal .message').html(April.data[April.index]);
        if (April.data.length == April.index) {
          April.index = 0;
        }
      })
    },
    InitSlide: function () {
      var data = this.getData();
      $('.april-next').on('click', function () {
        April.index++;
        $('#april-modal .april-next').html('');
        $('#april-modal .message').html(April.data[April.index]);
        $('.april-slide .message').html(April.data[April.index]);
        if (April.data.length == April.index) {
          April.index = 0;
        }
      })
    },
    getData: function getData() {
      var data;
      var response = [];
      Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vT-Dnoi5FUwW-6dLufUjAcuVyiv7EUjqXXamnay4vyg_1QhbIjOY-c2QbkBpv0j7kDxVrn-GHV83icM/pub?gid=0&single=true&output=csv', {
        download: true,
        dynamicTyping: true,
        complete: function (results) {
          April.data = results.data;
          $('#april-modal .message').html(April.data[April.index]);
          $('.april-slide .message').html(April.data[April.index]);
          // $('#april-modal').modal('show');
        }
      });
    },
  };

  return Public;
})();

function b64e(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(Number('0x' + p1));
  }));
}

function b64d(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  }).join(''));
}

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function setCookie(name, value, days) {
  if (days) {
    var date = new Date();
    var expires;
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function toggleLanguage(lang) {
  language = lang;
}

//payment pdf
function calculateInitialData(calculatorData, currencyData) {
  if (calculatorData.carPrice) {
    calculatorData.ownContribution.value =
      (calculatorData.carPrice.value *
        calculatorData.ownContribution.percent) /
      100;
    if (calculatorData.grantingCommission.source === "loanAmount") {
      calculatorData.loanAmount.value =
        ((calculatorData.carPrice.value -
            calculatorData.ownContribution.value) *
          calculatorData.currencyExchangeRates[
            calculatorData.activeCurrency
            ]) /
        currencyData[calculatorData.internActiveCurrency].releaseRate;
    }
    this.calculateGrantingCommission(calculatorData, currencyData);
    this.calculateOwnContributionPercent(calculatorData, currencyData);
  }
  this.changeSelectedMonth(calculatorData);
  this.convertRequestedAmount(calculatorData, currencyData);
  return this.makeAllCalculation(calculatorData, currencyData);
}

window.Navbar = Navbar;
window.CurrencyConverter = CurrencyConverter;
window.YoutubeAPI = YoutubeAPI;
window.MapAPI = MapAPI;
window.Inphographics = Inphographics;
window.TippyTiptool = TippyTiptool;
window.TermsConditions = TermsConditions;
window.VisitorPageScrollWatch = VisitorPageScrollWatch;
window.getCookie = getCookie;
window.setCookie = setCookie;
window.Slider = Slider;
window.ClientForm = ClientForm;
window.Advantages = Advantages;
window.April = April;
window.UserFeedback = UserFeedback;
window.Calculator = Calculator;
window.CurrencyConverter = CurrencyConverter;
window.toggleLanguage = toggleLanguage;
window.calculateInitialData = calculateInitialData;

