// CSS
require('font-awesome/css/font-awesome.css');
require('bootstrap/dist/css/bootstrap.css');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('./css/export.min.css');

require('./css/styles.css');

// JavaScript
window.$ = require('jquery');

window.jQuery = window.$;

window.slick = require('slick-carousel');
window.moment = require('moment');


require('bootstrap');

// require('easeljs');
// require('tippy.js');

// require('./js/tippy.all.min.js');

require('./js/jQueryRotate');
require('./js/lazysizes.min');

require('./js/public-js');
